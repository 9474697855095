import React, { useEffect, useState } from "react";
import { Footer } from "../Footer";
import { PiSecurityCameraBold } from "react-icons/pi";
import { TiWeatherCloudy } from "react-icons/ti";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import StaticIPhoneFrame from "../../assets/StaticIPhoneFrame";
import tesla from "../../assets/images/tesla.png";

export const DigitalGate = () => {
    const screenHeight = window.innerHeight;
    const wide = window.innerWidth > 760;
    const cameraYBase = wide ? -370 : -260;
    const lineYBase = wide ? 380 : 310;
    const [arrowOpacity, setArrowOpacity] = useState(1);
    const [cameraOpacity, setCameraOpacity] = useState(0);
    const [lineOpacity, setLineOpacity] = useState(0);
    const [licensePlateOpacity, setLicensePlateOpacity] = useState(0);
    const [lprOpacity, setLprOpacity] = useState(0);
    const [lineY, setLineY] = useState(lineYBase);
    const [cameraY, setCameraY] = useState(cameraYBase);
    const [phoneOpacity, setPhoneOpacity] = useState(0);
    const cdnUrl = "https://cdn.spotparking.app/public/site/";

    function ease(value) {
        return value * value * (3 - 2 * value);
    };

    const settings = {
        arrow: {
            scrollStart: 0,
            leaveStart: 0,
            scrollSpeed: 800,
        },
        camera: {
            scrollStart: 1000,
            leaveStart: 0,
            scrollSpeed: 2000,
        },
        line: {
            scrollStart: screenHeight,
            leaveStart: 0,
            scrollSpeed: 3000,
        },
        licensePlate: {
            scrollStart: screenHeight,
            leaveStart: 0,
            scrollSpeed: 3000,
        },
        lpr: {
            scrollStart: screenHeight * 2,
            leaveStart: 6000,
            scrollSpeed: 3000,
        },
        phone: {
            scrollStart: 8000,
            leaveStart: 6000,
            scrollSpeed: 3000,
        }
    };

    const handleScroll = () => {
        // Remove arrow
        if (window.scrollY >= 0) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.arrow.leaveStart) / (settings.arrow.scrollSpeed / 2)));
            setArrowOpacity(1 - ease(t));
        };

        // Add camera
        if (window.scrollY >= 0) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.camera.scrollStart) / (settings.camera.scrollSpeed / 2)));
            setCameraOpacity(ease(t));
        };

        // Add Line
        if (window.scrollY >= settings.line.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.line.scrollStart) / (settings.line.scrollSpeed / 2)));
            setLineOpacity(ease(t));
        };

        // Add License Plate
        if (window.scrollY >= settings.licensePlate.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.licensePlate.scrollStart) / (settings.licensePlate.scrollSpeed / 2)));
            setLicensePlateOpacity(ease(t));
        };

        // Add Phone
        if (window.scrollY >= settings.phone.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.phone.scrollStart) / (settings.phone.scrollSpeed / 2)));
            setPhoneOpacity(ease(t));
        } else {
            setPhoneOpacity(0);
        }

        // Add LPR
        if (window.scrollY >= settings.line.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.lpr.scrollStart) / (settings.lpr.scrollSpeed / 2)));
            setLprOpacity(ease(t));
        };

        // Move line up
        if (window.scrollY >= 5000) {
            const t = Math.max(0, Math.min(1, (window.scrollY - 8000) / (settings.lpr.scrollSpeed / 2)));
            setLineY(lineYBase - (ease(t) * 600));
            setCameraY(cameraYBase - (ease(t) * 600));
        };

        // Reduce LPR, Camera, and Cloud opacity after 6000 pixels
        if (window.scrollY >= 5000) {
            const t = Math.max(0, Math.min(1, (window.scrollY - 6000) / (settings.lpr.scrollSpeed / 2)));
            setLprOpacity(1 - ease(t));
            setLicensePlateOpacity(1 - ease(t));
        };

        // Reduce Phone opacity after 11000 pixels
        if (window.scrollY >= 11000) {
            const t = Math.max(0, Math.min(1, (window.scrollY - 11000) / (settings.lpr.scrollSpeed / 2)));
            setPhoneOpacity(1 - ease(t));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const BottomBox = ({ data }) => {
        return (
            <div className={`bg-gray-200 rounded-3xl hover:scale-105 hover:shadow-xl relative overflow-hidden flex flex-col justify-end group items-start w-[80%] lg:w-96 transition-all duration-200 cursor-pointer ${data.height}`}>
                <img src={data.url} alt="box" className="w-full h-full object-cover" />
                <p
                    className="text-white text-3xl font-bold text-center absolute bottom-6 left-6"
                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}
                >{data.title}</p>
                <p className="absolute w-full h-full left-1/2 -translate-x-1/2 p-2 z-50 opacity-0 text-center group-hover:opacity-100 flex transition-all duration-200 justify-center items-center text-white bg-gray-800/80">
                    {data.description}
                </p>
            </div>
        )
    }

    const boxesRow1 = [
        {
            title: "City Lots",
            height: 'h-96',
            url: `${cdnUrl}parkingLotOverhead.jpg`,
            description: "City Lots are the most common parking areas in urban areas. They are usually managed by the city and are open to the public. City lots are usually located near shopping centers, restaurants, and other attractions.",
        },
        {
            title: "Event Venues",
            height: 'h-96',
            url: `${cdnUrl}eventCenter.jpg`,
            description: "Event venues are places where people gather to watch performances, concerts, or other events. These venues can be indoor or outdoor and can range in size from small clubs to large stadiums.",
        },
        {
            title: "Airports",
            height: 'h-96',
            url: `${cdnUrl}airport.jpg`,
            description: "Airports are large facilities where airplanes take off and land. They are usually located outside of cities and have runways, terminals, and other facilities for passengers and cargo.",
        }
    ]

    const boxesRow2 = [
        {
            title: "Metered Spots",
            height: 'h-[500px]',
            url: `${cdnUrl}meteredSpots.png`,
            description: "Metered spots are parking spaces that require payment to park. They are usually found in urban areas and are managed by the city. Metered spots are usually located near shopping centers, restaurants, and other attractions.",
        },
        {
            title: "Hotels",
            height: 'h-[500px]',
            url: `${cdnUrl}hotel.jpeg`,
            description: "Hotels are places where people can stay overnight. They are usually located in cities and near airports. Hotels offer rooms, food, and other services to guests.",
        },
        {
            title: "Universities",
            height: 'h-[500px]',
            url: `${cdnUrl}universities.jpeg`,
            description: "Universities are large schools that offer undergraduate and graduate degrees. They are usually located in cities and have campuses with classrooms, dormitories, and other facilities.",
        }
    ]

    return (
        <div>
            {/* Tesla */}
            <img
                src={tesla}
                alt="tesla"
                className={`fixed left-1/2 -translate-x-1/2 top-1/2 -translate-y-[calc(50%-100px)] z-[1] scale-75 md:scale-100`}
            />

            {/* Horizontal Dotted Line */}
            {wide && (
                <div
                    className={`bg-transparent border-white border-b-8 fixed h-0 top-1/2 translate-y-[200px] md:translate-y-[-330px] left-1/2 -translate-x-[-220px] w-[200px] md:w-[100px] border-dashed`}
                    style={{ opacity: lprOpacity }}
                />
            )}

            {/* Vertical Dotted Line */}
            {wide && (
                <div
                    className={`bg-transparent border-white border-r-8 fixed w-0 top-1/2 translate-y-[200px] md:translate-y-[-315px] left-1/2 -translate-x-[-220px] h-[600px] md:w-[100px] border-dashed`}
                    style={{ opacity: lprOpacity }}
                />
            )}

            {/* Cloud Icon */}
            {wide && (
                <div
                    className="fixed top-1/2 translate-y-[370px] md:translate-y-[305px] left-1/2 translate-x-[185px] md:translate-x-[268px] bg-spotYellow p-4 rounded-full w-fit z-30"
                    style={{ opacity: lprOpacity }}
                >
                    <div className="z-50">
                        <TiWeatherCloudy size={wide ? 60 : 50} color="#323232" />
                    </div>
                </div>
            )}


            {/* Triangle */}
            <div
                className="fixed top-[calc(50%-235px)] md:top-[calc(50%-315px)] left-[calc(50%+15px)] md:left-[50.5%] z-[0]"
                style={{
                    width: 0,
                    height: 0,
                    borderBottom: wide ? '90px solid transparent' : '90px solid transparent',
                    borderRight: wide ? '80px solid transparent' : '90px solid transparent',
                    borderTop: wide ? '235px solid transparent' : '205px solid transparent',
                    borderLeft: wide ? '120px solid #ffffff80' : '90px solid #ffffff80',
                    transform: wide ? 'rotate(45deg)' : 'rotate(45deg)',
                    opacity: licensePlateOpacity,
                }}
            />


            {/* LPR Section */}
            {wide && (
                <div
                    className={`fixed top-1/2 translate-y-[300px] md:-translate-y-[280px] left-1/2 -translate-x-[450px]`}
                    style={{ opacity: licensePlateOpacity }}
                >
                    <div className="p-6 bg-transparent flex text-4xl font-bold justify-center items-center border-8 border-white text-white text-center rounded-lg w-fit">
                        <p className="bg-spotYellow p-2 text-spotGray rounded-md">ABC-123</p>
                    </div>
                    <p className="text-white font-semibold text-2xl mt-2 text-center">*License Plate <br /> Captured!</p>
                </div>
            )}

            {/* Lock Screen */}
            {window.scrollY < 13000 && window.innerWidth > 1024 && (
                <div
                    className={`fixed w-[500px] -bottom-[250px] left-1/2 -translate-x-[520px] xl:-translate-x-[600px] z-50 scale-110 lg:scale-75 xl:scale-100`}
                    style={{ opacity: phoneOpacity }}
                >
                    <StaticIPhoneFrame
                        imageSrc={`${cdnUrl}deviceRenders/lockScreen.png`}
                        rotate={0}
                        scale={1}
                        x={0}
                        y={0}
                        opacity={1}
                    />
                </div>
            )}

            {/* HEADER */}
            <div className="h-screen bg-gradient-to-b flex relative flex-col gap-6 from-white to-[#1F2225] pt-36 px-10 lg:px-24 xl:px-48">
                <h1 className="text-6xl lg:text-6xl xl:text-9xl text-center text-spotGray font-bold w-full">
                    Digital Gate
                </h1>
                <h1 className="text-xl lg:text-2xl xl:text-4xl text-center text-spotGray font-light w-full">
                    Hands-Free Parking Starts Here
                </h1>
                <div className="bg-gradient-to-b from-transparent to-spotYellow w-2 absolute h-1/2 bottom-0 left-1/2 -translate-x-[120px] md:-translate-x-[150px]" />
                <div className="bg-gradient-to-b from-transparent to-spotYellow w-2 absolute h-1/2 bottom-0 left-1/2 translate-x-[120px] md:translate-x-[150px]" />

                {/* Arrow */}
                <img
                    src={`${cdnUrl}yellowArrow.png`}
                    alt="yellowArrow"
                    className="absolute top-1/2 translate-y-[200px] scale-75 md:scale-100  md:translate-y-[260px] left-1/2 -translate-x-1/2"
                    style={{ opacity: arrowOpacity }}
                />
            </div>

            {/* SECTION 1 */}
            <div className="h-[15000px] relative pb-[1000px]">
                {/* Lines */}
                <div className="bg-spotYellow w-2 absolute h-full bottom-0 left-1/2 -translate-x-[120px] md:-translate-x-[150px]" />
                <div className="bg-spotYellow w-2 absolute h-full bottom-0 left-1/2 translate-x-[120px] md:translate-x-[150px]" />

                {/* Camera Icon */}
                <div
                    className="sticky top-1/2 left-1/2 bg-spotYellow p-4 rounded-full w-fit z-30"
                    style={{
                        opacity: cameraOpacity,
                        transform: `translateY(${cameraY}px) translateX(${wide ? '110px' : '85px'})`,
                    }}
                >
                    <div className="z-50">
                        <PiSecurityCameraBold size={wide ? 60 : 50} color="#323232" />
                    </div>
                </div>

                {/* Single Line */}
                <div
                    className={`bg-gradient-to-b from-transparent to-gray-200 sticky h-2 top-1/2 left-1/2 w-[300px] md:w-[400px]`}
                    style={{
                        opacity: lineOpacity,
                        transform: `translateY(${lineY}px) translateX(${wide ? '-50%' : '-21%'})`,
                    }}
                />
            </div>

            {/* SECTION 2 */}
            <div className="relative flex flex-col z-[10]">
                {/* Lock Screen Small */}
                {window.innerWidth < 1024 && (
                    <div
                        className={`z-[1000] mb-96 w-full justify-center items-center flex scale-110 lg:scale-75 xl:scale-100`}
                    >
                        <StaticIPhoneFrame
                            imageSrc={`${cdnUrl}deviceRenders/lockScreen.png`}
                            rotate={0}
                            scale={1}
                            x={0}
                            y={0}
                            opacity={1}
                        />
                    </div>
                )}
                <div className="w-screen bg-white h-fit z-[10000] p-8 lg:p-24 flex flex-col gap-8 lg:gap-24 justify-start items-center">
                    <div className="flex flex-col w-full gap-2 lg:gap-8 justify-center items-center">
                        <h2 className="text-spotGray font-bold text-3xl xl:text-8xl">Simple, seamless parking</h2>
                        <h2 className="text-gray-600 text-xl xl:text-5xl">Just 4 simple steps</h2>
                    </div>
                    <div className="flex flex-col w-fit justify-center items-start gap-4 lg:gap-12">
                        <div className="flex flex-row justify-center items-start gap-2 lg:gap-6">
                            <PiSecurityCameraBold size={wide ? 80 : 50} color="#323232" className="bg-spotYellow p-2 lg:p-3 rounded-full" />
                            <div className="flex flex-col gap-0 lg:gap-2 justify-center items-start">
                                <h2 className="text-spotGray text-xl xl:text-4xl font-bold">License Plate Captured</h2>
                                <p className="text-gray-600 text-md xl:text-2xl text-left">As you approach, the camera scans your license plate in real time.</p>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center items-start gap-2 lg:gap-6">
                            <TiWeatherCloudy size={wide ? 80 : 50} color="#323232" className="bg-spotYellow p-2 lg:p-3 rounded-full" />
                            <div className="flex flex-col gap-0 lg:gap-2 justify-center items-start">
                                <h2 className="text-spotGray text-xl xl:text-4xl font-bold">Cloud Processing</h2>
                                <p className="text-gray-600 text-md xl:text-2xl text-left">The system instantly checks for your parking permit or starts a timed session.</p>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center items-start gap-2 lg:gap-6">
                            <MdOutlineNotificationsActive size={wide ? 80 : 50} color="#323232" className="bg-spotYellow p-2 lg:p-3 rounded-full" />
                            <div className="flex flex-col gap-0 lg:gap-2 justify-center items-start">
                                <h2 className="text-spotGray text-xl xl:text-4xl font-bold">Instant Notification</h2>
                                <p className="text-gray-600 text-md xl:text-2xl text-left">You receive a confirmation on your phone—no tickets, no hassle.</p>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center items-start gap-2 lg:gap-6">
                            <FaCar size={wide ? 80 : 50} color="#323232" className="bg-spotYellow p-2 lg:p-3 rounded-full" />
                            <div className="flex flex-col gap-0 lg:gap-2 justify-center items-start">
                                <h2 className="text-spotGray text-xl xl:text-4xl font-bold">Drive In & Park</h2>
                                <p className="text-gray-600 text-md xl:text-2xl text-left">No stopping, no scanning—just park and go.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SECTION 3 */}
            <div className="h-screen relative z-40">
                {/* Lines */}
                <div className="bg-spotYellow w-2 absolute h-full bottom-0 left-1/2 -translate-x-[120px] md:-translate-x-[150px]" />
                <div className="bg-spotYellow w-2 absolute h-full bottom-0 left-1/2 translate-x-[120px] md:translate-x-[150px]" />
                <h2 className="text-white text-4xl bg-[#1F2225] sm:bg-transparent py-10 px-8 sm:px-0 sm:py-0 lg:text-6xl xl:text-7xl font-bold absolute top-0 sm:top-24 left-1/2 -translate-x-1/2 lg:-translate-x-[450px] xl:-translate-x-[500px] text-center">Drive Out</h2>
                <h2 className="text-white bg-[#1F2225] sm:bg-transparent py-10 mt-10 sm:mt-0 px-8 sm:px-0 sm:py-0 text-4xl lg:text-6xl xl:text-7xl font-bold absolute top-24 left-1/2 -translate-x-1/2 sm:-translate-x-[-200px] xl:translate-x-[220px] text-center">Hands Free</h2>
            </div>

            {/* SECTION 4 */}
            <div className="h-auto bg-[#1F2225] w-full relative z-[10] py-24 flex flex-col justify-start items-center gap-10">
                <h2 className="text-white text-4xl lg:text-6xl xl:text-7xl font-bold text-center">One Solution.</h2>
                <h2 className="text-white text-4xl lg:text-6xl xl:text-7xl font-bold text-center">Works for All.</h2>
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-4 mt-12 mx-2 sm:mx-12">
                        {boxesRow1.map((box, index) => {
                            return <BottomBox key={index} data={box} />
                        })}
                    </div>
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-4 mx-2 sm:mx-12">
                        {boxesRow2.map((box, index) => {
                            return <BottomBox key={index} data={box} />
                        })}
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className="relative z-[2000]">
                <Footer bg={'#1F2225'} textColor={'#fff'} />
            </div>
        </div >
    );
}