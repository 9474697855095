import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { Toaster, toast } from 'sonner';
import { Footer } from "../Footer";
import { Helmet } from "react-helmet-async";
import Arrow from "../Arrow";
import { Faq } from "../Faq";

export const ContactUs = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const radius = Math.round((scrollPosition * 120));
    const width = 100 - (scrollPosition * 10);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [submitMessage, setSubmitMessage] = useState("Submit");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !email || !phone || !message) {
            toast.error("Make sure to fill out all the fields!");
            return;
        }

        setSubmitMessage("Message has been sent!");

        const templateParams = {
            name,
            email,
            phone,
            message
        };

        // Send email using emailjs
        emailjs
            .send(
                "service_dsu84xg",
                "template_ho732mv",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setTimeout(() => {
            setSubmitMessage("Submit Another Message");
        }, 2000);
    }

    return (
        <div className="flex flex-col justify-center items-center bg-lightYellow font-rubik">
            <Helmet>
                <title>Spot Parking | Contact Us</title>
                <meta name="description" content="Need assistance with Spot Parking? Reach out to us via our easy-to-use contact form or explore our comprehensive FAQ section. We're ready to help with your parking queries." />
                <link rel="canonical" href="https://spotparking.app/contact-us" />
            </Helmet>
            <div className="flex flex-col w-screen justify-center items-center h-fit min-h-screen pb-12 gray-gradient"
                style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }}>
                <div className="w-screen">
                    <h1 className="xl:text-8xl md:text-6xl sm:text-5xl text-4xl transition-all blend font-bold text-center font-stretched text-white">Have More Questions?</h1>
                    <p className="text-center xl:text-4xl md:text-3xl sm:text-2xl text-xl blend mt-2 text-white">We’d love to hear from you.</p>
                </div>
                <form className="fcc gap-2 mt-8 bg-transparent w-10/12 md:w-1/2 xl:w-1/3" onSubmit={handleSubmit}>
                    <input className="p-2 rounded-md w-full outline-spotYellow border-2 border-gray-500 bg-gray-600 text-white" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input className="p-2 rounded-md w-full outline-spotYellow border-2 border-gray-500 bg-gray-600 text-white" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input className="p-2 rounded-md w-full outline-spotYellow border-2 border-gray-500 bg-gray-600 text-white" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <textarea className="p-2 rounded-md w-full outline-spotYellow border-2 border-gray-500 bg-gray-600 text-white" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button className="p-2 rounded-lg w-full outline mt-1 outline-2 outline-offset-2 outline-yellow-200 text-white gray-gradient hover:outline-none hover:yellow-gradient hover:text-spotGray">{submitMessage}</button>
                </form>
                <Arrow />
            </div>
            <div className="w-full flex flex-col justify-center items-center">
                <div className="cu-sec2-text-container">
                    <h1 className="w-full text-center xl:text-6xl lg:text-5xl md:text-4xl text-3xl blend font-bold py-8">Frequently Asked Questions</h1>
                </div>
                <Faq width={"w-[80%]"} />
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Footer bg={"#FFF9E1"} />
            </div>
            <Toaster textColor={"#323232"} />
        </div>
    )
}