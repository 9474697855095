import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";

export const Solutions = () => {
    const [gradientPosition1, setGradientPosition1] = useState({ x: "", y: "" });
    const [gradientPosition2, setGradientPosition2] = useState({ x: "", y: "" });
    const [gradientPosition3, setGradientPosition3] = useState({ x: "", y: "" });
    const cdnUrl = "https://cdn.spotparking.app/public/site/";

    // Handler for mouse movement
    const handleMouseMove1 = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;

        setGradientPosition1({ x: `${x}%`, y: `${y}%` });
    };
    const handleMouseMove2 = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;

        setGradientPosition2({ x: `${x}%`, y: `${y}%` });
    };
    const handleMouseMove3 = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;

        setGradientPosition3({ x: `${x}%`, y: `${y}%` });
    };

    return (
        <div className="w-screen h-screen bg-gray-800 flex justify-center">
            <div className="grid xl:grid-cols-3 grid-row-3 md:gap-16 gap-8 blend mx-8 bg-transparent h-5/6 my-auto w-full text-white 2xl:text-6xl xl:text-5xl lg:text-4xl text-3xl font-bold text-center font-rubik">
                <Link to={"/capacityMonitoring"}
                    className="flex flex-col justify-center items-center relative rounded-xl blend cursor-pointer bg-gray-800 backdrop-blur-sm z-10 group"
                    onMouseMove={handleMouseMove1}
                    onMouseLeave={() => setGradientPosition1({ x: "", y: "" })}
                    tiltMaxAngleX={10} tiltMaxAngleY={5}
                >
                    <div className="w-full h-full flex flex-col items-center rounded-xl justify-center gap-2 bg-gray-800 z-50 blend"
                        style={{ boxShadow: "0 0 12px 12px #00000020" }}>
                        <div className="flex flex-col items-center justify-center z-50">
                            <img src={`${cdnUrl}nav.png`} alt="capacity" className="xl:w-48 xl:h-48 xl:mb-8 w-20 h-20 mb-2" />
                            <p>Capacity</p>
                            <p>Monitoring</p>
                        </div>
                        <img src={`${cdnUrl}capacity.jpg`} alt="capacity" className="w-full h-full absolute z-40 xl:object-center object-top object-cover rounded-xl opacity-10 md:opacity-0 md:group-hover:opacity-10 grayscale blend" />
                        <FaCircleArrowRight aria-label="arrow" className="md:group-hover:text-[#ffffff40] text-[#ffffff40] md:text-transparent xl:text-[100px] lg:text-[60px] text-[50px] animate-pulse blend absolute xl:bottom-12 xl:right-1/2 md:top-auto bottom-6 right-12 translate-x-1/2 z-50" />
                    </div>
                    <div className="pointer-events-none absolute -top-[10px] -left-[10px] w-[calc(100%+20px)] h-[calc(100%+20px)] bg-gradient-to-r from-yellow-200 to-transparent rounded-lg"
                        style={{
                            "--x": gradientPosition1.x,
                            "--y": gradientPosition1.y,
                            background: `radial-gradient(circle at var(--x) var(--y), rgba(254, 240, 138, 0.25) 20%, transparent 60%)`,
                        }}
                    ></div>
                </Link>
                <Link to={"/digitalGate"}
                    className="flex flex-col justify-center group items-center rounded-xl shadow-xl blend cursor-pointer bg-gray-800 backdrop-blur-sm z-10"
                    onMouseMove={handleMouseMove2}
                    onMouseLeave={() => setGradientPosition2({ x: "", y: "" })}
                    tiltMaxAngleX={10} tiltMaxAngleY={5}
                >
                    <div className="w-full h-full flex flex-col items-center rounded-xl justify-center gap-2 bg-gray-800 z-50"
                        style={{ boxShadow: "0 0 12px 12px #00000020" }}>
                        <div className="flex flex-col items-center justify-center z-50">
                            <img src={`${cdnUrl}admin.png`} alt="capacity" className="xl:w-48 xl:h-48 xl:mb-8 w-20 h-20 mb-2" />
                            <p>Digital</p>
                            <p>Gate</p>
                        </div>
                        <img src={`${cdnUrl}lpr.jpg`} alt="capacity" className="w-full h-full absolute z-40 xl:object-center object-top object-cover rounded-xl opacity-10 md:opacity-0 md:group-hover:opacity-10 grayscale blend" />
                        <FaCircleArrowRight aria-label="arrow" className="md:group-hover:text-[#ffffff40] text-[#ffffff40] md:text-transparent xl:text-[100px] lg:text-[60px] text-[50px] animate-pulse blend absolute xl:bottom-12 xl:right-1/2 md:top-auto bottom-6 right-12 translate-x-1/2 z-50" />
                    </div>
                    <div className="pointer-events-none absolute -top-[10px] -left-[10px] w-[calc(100%+20px)] h-[calc(100%+20px)] bg-gradient-to-r from-yellow-200 to-transparent rounded-lg"
                        style={{
                            "--x": gradientPosition2.x,
                            "--y": gradientPosition2.y,
                            background: `radial-gradient(circle at var(--x) var(--y), rgba(254, 240, 138, 0.25) 20%, transparent 60%)`,
                        }}
                    ></div>
                </Link>
                <Link to={"/automatedEnforcement"}
                    className="flex flex-col justify-center items-center rounded-xl shadow-xl blend group cursor-pointer bg-gray-800 backdrop-blur-sm z-10"
                    onMouseMove={handleMouseMove3}
                    onMouseLeave={() => setGradientPosition3({ x: "", y: "" })}
                    tiltMaxAngleX={10} tiltMaxAngleY={5}
                >
                    <div className="w-full h-full flex flex-col items-center rounded-xl justify-center gap-2 bg-gray-800 z-50"
                        style={{ boxShadow: "0 0 12px 12px #00000020" }}>
                        <div className="flex flex-col items-center justify-center z-50">
                            <img src={`${cdnUrl}watch.png`} alt="capacity" className="xl:w-48 xl:h-48 xl:mb-8 w-20 h-20 mb-2" />
                            <p>Automated</p>
                            <p>Enforcement</p>
                        </div>
                        <img src={`${cdnUrl}enf.png`} alt="capacity" className="w-full h-full absolute z-40 xl:object-center object-top object-cover rounded-xl opacity-10 md:opacity-0 md:group-hover:opacity-10 grayscale blend" />
                        <FaCircleArrowRight aria-label="arrow" className="md:group-hover:text-[#ffffff40] text-[#ffffff40] md:text-transparent xl:text-[100px] lg:text-[60px] text-[50px] animate-pulse blend absolute xl:bottom-12 xl:right-1/2 md:top-auto bottom-6 right-12 translate-x-1/2 z-50" />
                    </div>
                    <div className="pointer-events-none absolute -top-[10px] -left-[10px] w-[calc(100%+20px)] h-[calc(100%+20px)] bg-gradient-to-r from-yellow-200 to-transparent rounded-lg"
                        style={{
                            "--x": gradientPosition3.x,
                            "--y": gradientPosition3.y,
                            background: `radial-gradient(circle at var(--x) var(--y), rgba(254, 240, 138, 0.25) 20%, transparent 60%)`,
                        }}
                    ></div>
                </Link>
            </div>
        </div>
    )
}