import React, { useState } from 'react';

export const ContactForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const API_KEY = process.env.REACT_APP_API_KEY;

        try {
            const response = await fetch('https://1u5ksut8he.execute-api.us-east-1.amazonaws.com/default/HubSpotIntegration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY
                },
                body: JSON.stringify({
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    company: formData.company,
                }),
            });

            if (response.ok) {
                console.log('Contact information submitted successfully!');
                if (onSubmit) {
                    onSubmit();
                }
            } else {
                console.log('Failed to submit contact information.');
            }
        } catch (error) {
            console.error('Error:', error);
            console.log('An error occurred while submitting the information.');
        }
    };

    return (
        <div className="fcc">
            <p className='pb-2 font-bold text-white text-5xl'>Instant ROI Calculator</p>
            <p className='pb-8 font-medium text-gray-300 text-xl'>Enter details below</p>
            <form onSubmit={handleSubmit} className='w-full flex flex-col items-center'>
                <div className="flex flex-col items-end mr-28 gap-5 mb-5">
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">First Name:</div>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                            required
                        />
                    </div>
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">Last Name:</div>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                            required
                        />
                    </div>
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">Organization:</div>
                        <input
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                            required
                        />
                    </div>
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">Job Role:</div>
                        <input
                            type="text"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                            required
                        />
                    </div>
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">Email:</div>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                            required
                        />
                    </div>
                    <div className='text-white flex flex-row justify-end items-center w-full'>
                        <div className="flex min-w-fit mr-2 text-lg">Phone:</div>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className='bg-transparent border w-64 rounded-md p-1 pl-2 text-lg border-white text-white'
                        />
                    </div>
                </div>
                <button type="submit" className='text-spotGray w-fit h-fit p-2 yellow-gradient rounded-xl shadow-md text-2xl font-bold'>Submit</button>
            </form>
        </div>
    );
};