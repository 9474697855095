import React, { useEffect, useState } from "react";
import { fetchData } from "./fetcher";
import BarLoader from "react-spinners/BarLoader";

const WebMapPicker = () => {
    const [organization, setOrganization] = useState("");
    const [allOrganizations, setAllOrganizations] = useState([]);
    const [darkMode, setDarkMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const bgImage = darkMode ? "fractalDark.png" : "fractalLight.png";
    const cdnUrl = "https://cdn.spotparking.app/public/site/";

    useEffect(() => {
        const getOrgs = async () => {
            const response = await fetchData('/public/getOrganizations?public=true');
            console.log(response)
            setAllOrganizations(response.organizations);
        };

        getOrgs();
    }, []);

    const getOrgCoordinates = async () => {
        setLoading(true);
        const response = await fetchData('/public/getOrganizations?orgName=' + organization + '&columns=orgID,orgCoordinates,defaultMapZoom,permitTypes,spots,lines')

        const orgDataResponse = response.organization

        for (let i in Object.keys(orgDataResponse)) {
            const currentAttribute = Object.keys(orgDataResponse)[i]
            const attributeData = orgDataResponse[currentAttribute]

            sessionStorage.setItem(currentAttribute, JSON.stringify(attributeData))
        };

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("coords", response.organization.orgCoordinates);
        queryParams.set("orgID", response.organization.orgID);
        window.location.href = `/webmap?${queryParams.toString()}`;
    };

    return (
        <div className="h-screen w-screen overflow-hidden flex flex-col gap-4 justify-center items-center bg-transparent">
            <img src={`${cdnUrl}${bgImage}`} alt="Fractal Design" className="absolute top-0 left-0 w-screen h-auto overflow-hidden object-contain -z-10" />
            <img src={`${cdnUrl}logoTransparent.png`} alt="Spot Logo" className="absolute top-24 w-3/4 lg:w-96 h-auto object-contain" />
            {(!allOrganizations && allOrganizations.length === 0) || loading ? (
                <BarLoader
                    color={'white'}
                    height={8}
                    width={150}
                />
            ) : (
                <div className="flex flex-col gap-4 justify-start items-center mt-32 h-64">
                    <h2 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-spotGray'}`}>Where would you like to park?</h2>
                    <select
                        className="border-gray-400 border-2 rounded-lg p-2 outline-spotYellow min-w-64"
                        onChange={(e) => setOrganization(e.target.value)}
                    >
                        <option value=""></option>
                        {allOrganizations.map((org, index) => (
                            <option key={index} value={org}>{org}</option>
                        ))}
                    </select>
                    {organization && organization !== "" && (
                        <button className="yellow-gradient font-bold text-spotGray p-2 rounded-lg active:opacity-50" onClick={getOrgCoordinates}>
                            Submit
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default WebMapPicker;