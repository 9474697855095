import React, { useState, useEffect } from "react";
import { fetchData } from "./fetcher";
import { Footer } from "../Footer";

const News = () => {
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getArticles = async () => {
            const response = await fetchData("/public/news");

            response.sort((a, b) => new Date(b.date) - new Date(a.date));

            setArticles(response);
            setLoading(false);
        };

        getArticles();
    }, []);

    const NewsItem = ({ newsObj }) => {
        return (
            <div href={newsObj.url} target="_blank" rel="noreferrer" className="w-full md:w-4/5 flex flex-col md:flex-row justify-center items-center gap-4 h-auto md:h-72 p-4 rounded-lg">
                <a href={newsObj.url} target="_blank" rel="noreferrer" className="w-full md:w-1/3 h-48 md:h-full">
                    <img src={newsObj.image} alt="Deseret News" className="object-cover rounded-lg hover:scale-105 transition-all duration-200" />
                </a>
                <div className="w-full md:w-2/3 flex flex-col justify-center items-start gap-4">
                    <h2 className="text-2xl text-white font-bold">{newsObj.title}</h2>
                    <p className="text-white">{newsObj.date}</p>
                    <p className="text-white">{newsObj.description}</p>
                    <a href={newsObj.url} target="_blank" rel="noreferrer" className="text-spotYellow hover:underline">Learn More</a>
                </div>
            </div>
        );
    };

    return (
        <div className="w-full flex flex-col justify-center items-center gap-8 pt-24 pb-10 font-rubik">
            <h1 className="text-5xl lg:text-6xl 2xl:text-8xl text-white font-bold">News</h1>
            <hr className="w-4/5 bg-white opacity-50" />
            {loading ? <p className="text-white">Loading...</p> :
                <div className="w-full flex flex-col justify-center items-center gap-8 flex-wrap">
                    {articles.map((newsObj, index) => <NewsItem key={index} newsObj={newsObj} />)}
                </div>
            }
            <Footer bg='transparent' textColor='white' />
        </div>
    );
}

export default News;