import React, { useState, useEffect } from "react";
import { IoPerson } from "react-icons/io5";
import { FaChartArea, FaCalendar, FaFileInvoice, FaChartLine, FaDollarSign, FaMap } from "react-icons/fa";
import { FaSquareParking } from "react-icons/fa6";
import { PiIdentificationCardFill } from "react-icons/pi";
import { MdStickyNote2 } from "react-icons/md";
import Map from "./features/liveMap/Map"
import { Footer } from "../Footer";
import Arrow from "../Arrow";
import { Helmet } from "react-helmet-async";
import InfinityScroll from "../InfinityScroll";
import StaticIPhoneFrame from "../../assets/StaticIPhoneFrame"

const cdnUrl = "https://cdn.spotparking.app/public/site/";

export const AutomatedEnforcement = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const borderRadius = Math.round((scrollPosition * 120));

    const statItem = (icon, title, description) => {
        return (
            <div className="flex flex-col px-20 lg:px-0 lg:flex-row w-fit justify-center items-start">
                <div className="group hover:bg-grey-500 bg-spotYellow w-12 h-12 justify-center items-center flex rounded-lg mr-2">
                    <div className="group-hover:scale-x-[-1] text-3xl w-12 flex justify-center items-center transition-all duration-300 text-spotGray">
                        {icon}
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <h1 className="text-xl mg:text-2xl lg:text-4xl font-semibold">{title}</h1>
                    <p className="text-sm lg:text-md mb-4 rubik-light">{description}</p>
                </div>
            </div>
        )
    };

    const IndustryCard = ({ title, description, image }) => {
        return (
            <div className='flex flex-col w-96 items-center gap-5'>
                <StaticIPhoneFrame
                    imageSrc={image}
                    rotate={0}
                    scale={1}
                    x={0}
                    y={0}
                    opacity={1}
                    height={300}
                />
                <h2 className='text-2xl font-bold w-full text-center'>{title}</h2>
                <p className='text-lg w-full text-left'>{description}</p>
            </div>
        );
    };

    const [gradientPosition, setGradientPosition] = useState({ x: "50%", y: "50%" });

    // Handler for mouse movement
    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;

        setGradientPosition({ x: `${x}%`, y: `${y}%` });
    };

    const cdnUrl = "https://cdn.spotparking.app/public/site/";

    return (
        <div className="flex justify-center items-center flex-col h-auto bg-lightYellow font-rubik">
            <Helmet>
                <title>Spot Parking | Features</title>
                <meta name="description" content="Explore Spot Parking's cutting-edge features like real-time maps, detailed violation management, and insightful analytics to streamline parking enforcement on your campus." />
                <link rel="canonical" href="https://spotparking.app/features" />
            </Helmet>
            <div className="flex flex-row h-screen justify-center lg:justify-start items-center w-full overflow-hidden gray-gradient" style={{
                borderBottomLeftRadius: `${borderRadius}px`,
                borderBottomRightRadius: `${borderRadius}px`,
            }}>
                <div
                    className="flex flex-col h-fit text-left z-20 text-wrap bg-gray-700 bg-opacity-20 transition-all duration-500 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden absolute top-1/3 font-rubik w-fit max-w-[90%] lg:max-w-[70%] xl:max-w-[50%] lg:ml-[10%]"
                    onMouseMove={handleMouseMove}
                    onMouseLeave={() => setGradientPosition({ x: "", y: "" })}
                    style={{
                        "--x": gradientPosition.x,
                        "--y": gradientPosition.y,
                    }}
                    tiltMaxAngleX={10} tiltMaxAngleY={5}
                >
                    <div className="flex flex-col p-8 gap-5 justify-center text-white w-fit h-fit font-semibold text-lg">
                        <h1 className="flex flex-row font-bold text-2xl sm:text-4xl lg:text-6xl font-stretched">Automated Enforcement</h1>
                        <p className="text-white text-lg lg:text-xl xl:text-2xl mt-0 rubik-normal">Spot Parking utilizes a high end object detection technology to enhance parking enforcement on college campuses. Its advanced system, powered by machine learning algorithms, enables precise and efficient identification of vehicles entering and exiting parking lots.</p>
                    </div>
                    <div className="pointer-events-none absolute top-0 left-0 w-full h-full bg-gradient-to-r from-yellow-200 to-transparent rounded-lg"
                        style={{
                            background: `radial-gradient(circle at var(--x) var(--y), rgba(254, 240, 138, 0.05) 30%, transparent 80%)`,
                        }}
                    ></div>
                </div>
                <div className="ml-96 left-0" style={{ filter: `blur(${scrollPosition * 10}px)` }}>
                    <InfinityScroll />
                </div>
                <Arrow />
            </div>
            <div className="flex-col-center down-lg z-10 w-full no-scrollbar pt-10" id="section2">
                <div className="overflow-x-auto w-full p-4">
                    <div className="flex flex-col sm:flex-row gap-10 w-max flex-nowrap px-4">
                        {appData.map((card, index) => (
                            <IndustryCard
                                key={index}
                                title={card.title}
                                description={card.description}
                                image={card.image}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center h-[90vh] w-full overflow-hidden  relative" id="section3">
                <div className={`flex flex-col items-center absolute left-8 top-8 h-auto text-sm lg:text-xl font-semibold w-auto p-4 text-center bg-gray-700/20 backdrop-blur-sm rounded-lg z-40 transition-all duration-200`} id="tooltip">
                    Drag to explore campus!
                </div>
                <div className={`flex flex-row gap-2 items-center absolute right-8 bottom-8 h-auto text-xl font-semibold w-auto p-4 text-center bg-gray-700/20 backdrop-blur-sm rounded-lg z-[40]`} id="tooltip">
                    <div className="bg-[#aad7a4] w-6 h-6 rounded-full"></div>
                    Permit
                    <div className="bg-[#D1807D] w-6 h-6 rounded-full"></div>
                    No Permit
                </div>
                <div id="map" className="map-container w-full">
                    <Map />
                </div>
            </div>
            <div className="flex flex-col justify-center items-center h-fit w-full bg-white overflow-hidden shadow-lg" id="section4">
                <h1 className={`font-bold text-3xl md:text-4xl lg:text-6xl my-8 transition-all duration-200`}>Statistical Analysis</h1>
                <div className={`transition-all duration-200 flex flex-col lg:flex-row justify-center items-center`}>
                    <div className="flex flex-col justify-center items-start">
                        {stats.map((currentStat, index) => (
                            statItem(currentStat.icon, currentStat.title, currentStat.description)
                        ))}
                    </div>
                    <img src={`${cdnUrl}deviceRenders/desktopRender.png`} alt="desktop" className="h-auto w-[65%] lg:w-[35%] object-contain mr-[30px]" />
                </div>
            </div>
            <div className="flex-col-center gray-gradient w-full text-white pb-6">
                <h1 className={`flex self-center text-3xl md:text-4xl lg:text-6xl font-bold my-8 transition-all duration-200`}>Admin Portal</h1>
                <div className={`flex flex-col lg:flex-row items-center gap-10 justify-center transition-all duration-200`}>
                    <img src={`${cdnUrl}deviceRenders/adminLaptop.png`} alt="desktop" className="w-[65%] lg:w-[35%]" />
                    <div className="flex flex-col justify-center items-start">
                        {adminItems.map((currentStat, index) => (
                            statItem(currentStat.icon, currentStat.title, currentStat.description)
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-8 justify-center items-center w-full h-fit p-4 text-spotGray">
                <div className="h-[600px] flex flex-row -mt-44 lg:mt-10 justify-center items-center scale-[30%] lg:scale-100">
                    <div className="translate-x-44">
                        <StaticIPhoneFrame
                            imageSrc={`${cdnUrl}deviceRenders/mapView.png`}
                            rotate={0}
                            scale={0.7}
                            x={0}
                            y={-50}
                            opacity={1}
                        />
                    </div>
                    <div className="z-50">
                        <StaticIPhoneFrame
                            imageSrc={`${cdnUrl}deviceRenders/splashScreen.png`}
                            rotate={0}
                            scale={0.7}
                            x={0}
                            y={0}
                            opacity={1}
                        />
                    </div>
                    <div className="-translate-x-44">
                        <StaticIPhoneFrame
                            imageSrc={`${cdnUrl}deviceRenders/listView.png`}
                            rotate={0}
                            scale={0.7}
                            x={0}
                            y={-50}
                            opacity={1}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center -mt-52 lg:-mt-0">
                    <h1 className={`flex text-3xl lg:text-4xl font-bold transition-all duration-200 delay-200`}>Find Your Spot.</h1>
                    <p className={`text-lg lg:text-2xl rubik-light w-[80%] text-center transition-all duration-200 delay-300`}>Our consumer app provides real-time updates on available parking spots, helping students and faculty quickly find parking on campus. With an easy-to-use interface, it ensures a hassle-free parking experience by showing the closest available spaces in real-time.</p>
                </div>
            </div>
            <Footer bg={"transparent"} textColor={"#323232"} />
        </div>
    )
};

const appData = [
    {
        image: `${cdnUrl}deviceRenders/splashScreen.png`,
        title: "Spot Parking App",
        description: "The Spot Parking App empowers parking enforcement officials to optimize operations, promote compliance, and enhance campus safety.",
        entrance: 0,
    },
    {
        image: `${cdnUrl}deviceRenders/settings.jpeg`,
        title: "Customizable Settings",
        description: "Tailor your parking enforcement experience with customizable profile settings. Personalize your preferences, notifications, and user interface to suit your specific needs and workflow.",
        entrance: 1,
    },
    {
        image: `${cdnUrl}deviceRenders/mapView.png`,
        title: "Map View",
        description: "Navigate campus parking lots effortlessly with a interactive map. Gain real-time insights into parking availability, violations, and occupancy status across all campus lots.",
        entrance: 2
    },
    {
        image: `${cdnUrl}deviceRenders/listView.png`,
        title: "List View",
        description: "Stay organized and informed with List View. Access a detailed and sorted list of active parking violations, complete with essential information such as vehicle details, violation types, and timestamps.",
        entrance: 3
    },
];

const stats = [
    {
        icon: <FaCalendar />,
        title: "Number of Violations",
        description: "Track the number of violations issued daily, weekly, monthly, and yearly."
    },
    {
        icon: <FaFileInvoice />,
        title: "Most Common Violations",
        description: "See which violations occur most frequently."
    },
    {
        icon: <FaSquareParking />,
        title: "Parking Lot Occupancy",
        description: "Monitor space usage in real-time and optimize parking allocation."
    },
    {
        icon: <FaChartLine />,
        title: "Citation Trends",
        description: "Track the number and type of citations issued over time."
    },
    {
        icon: <FaDollarSign />,
        title: "Revenue Trends",
        description: "Analyze parking pass and citation revenue to predict future trends."
    },
];

const adminItems = [
    {
        icon: <FaChartArea />,
        title: "Insights and Trends",
        description: "See and analyze parking data to identify patterns, peak usage times, and common violations, helping to make informed decisions."
    },
    {
        icon: <FaMap />,
        title: "Live Interactive Map",
        description: "View the status of every spot and track the locations of all employees and university enforcement vehicles."
    },
    {
        icon: <PiIdentificationCardFill />,
        title: "Plate History",
        description: "See a log of every vehicle entry, including entry times, visit frequency, and citations. Easily identify repeat offenders, manage and update citations, and ensure accurate enforcement."
    },
    {
        icon: <MdStickyNote2 />,
        title: "Violation Management",
        description: "Generate and view detailed reports on parking violations, including information such as the time of the infraction, the specific location where it occurred, and the type of violation committed."
    },
    {
        icon: <IoPerson />,
        title: "Customizable Settings",
        description: "Adjust and customize violation detection criteria, enforcement rules, parking permit types, parking lots, and employee information."
    },
];