import React, { useEffect, useState } from "react";
import { fetchData } from "./fetcher";
import BarLoader from "react-spinners/BarLoader";

const SignHandler = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        setDarkMode(darkModeMediaQuery.matches);

        const handleChange = (e) => {
            setDarkMode(e.matches);
        };

        darkModeMediaQuery.addEventListener("change", handleChange);

        return () => {
            darkModeMediaQuery.removeEventListener("change", handleChange);
        };
    }, []);

    useEffect(() => {
        const sendCount = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id').toUpperCase();
            const response = await fetchData(`/signs?id=${id}`);
            const coords = response?.coordinates;
            const zoom = response?.zoom;
            const orgID = response?.orgID;

            const secondResponse = await fetchData('/public/getOrganizations?orgID=' + orgID + '&columns=orgID,orgCoordinates,defaultMapZoom,permitTypes,spots,lines')

            const orgDataResponse = secondResponse.organization

            for (let i in Object.keys(orgDataResponse)) {
                const currentAttribute = Object.keys(orgDataResponse)[i]
                const attributeData = orgDataResponse[currentAttribute]

                sessionStorage.setItem(currentAttribute, JSON.stringify(attributeData))
            };

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("coords", orgDataResponse.orgCoordinates);
            queryParams.set("orgID", orgDataResponse.orgID);
            window.location.href = `/webmap?${queryParams.toString()}`;

            sessionStorage.setItem('orgID', orgID);

            if (coords) {
                window.location.href = `/webmap?coords=${coords}&zoom=${zoom}`;
                setMessage(coords);
            } else {
                window.location.href = `/webmapPicker`;
                setMessage("Sign not found, redirecting to the map picker...");
            };
        };

        sendCount();
    }, []);

    return (
        <div className={`h-screen w-screen justify-center items-center flex overflow-hidden ${darkMode ? 'bg-transparent' : 'bg-white'} `}>
            {loading ? (
                <BarLoader
                    color={'white'}
                    height={8}
                    width={150}
                />
            ) : (
                <h2 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-spotGray'}`}>{message}</h2>
            )}
        </div>
    );
};

export default SignHandler;

// signHandler?id=LGTP