import * as React from "react"
const IPhoneFrame = ({ imageSrc, imageSrc2, rotate, scale, x, coverOpacity1, coverOpacity2, totalOpacity }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            width={356}
            height={730}
            opacity={totalOpacity}
            viewBox="0 0 94.192 193.146"
            transform={`rotate(${rotate}) scale(${scale}) translate(0, ${x})`}
            transform-origin="center"
        >
            <defs>
                <linearGradient id="a">
                    <stop
                        offset={0}
                        style={{
                            stopColor: "#7c6655",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                    <stop
                        offset={0.124}
                        style={{
                            stopColor: "#f8eadd",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                    <stop
                        offset={0.765}
                        style={{
                            stopColor: "#aa998b",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                    <stop
                        offset={0.985}
                        style={{
                            stopColor: "#817469",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: "#bcad9a",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                </linearGradient>
                <linearGradient
                    xlinkHref="#a"
                    id="c"
                    x1={305.225}
                    x2={305.225}
                    y1={62.326}
                    y2={85.162}
                    gradientTransform="translate(-11.103)"
                    gradientUnits="userSpaceOnUse"
                    z={100}
                />
                <linearGradient
                    xlinkHref="#a"
                    id="d"
                    x1={305.225}
                    x2={305.225}
                    y1={62.326}
                    y2={75.992}
                    gradientTransform="translate(-103.313 -9.783)"
                    gradientUnits="userSpaceOnUse"
                    z={100}
                />
                <linearGradient
                    xlinkHref="#a"
                    id="e"
                    x1={305.225}
                    x2={305.225}
                    y1={62.326}
                    y2={70.357}
                    gradientTransform="translate(-103.313 -24.727)"
                    gradientUnits="userSpaceOnUse"
                    z={100}
                />
                <linearGradient
                    xlinkHref="#a"
                    id="f"
                    x1={305.225}
                    x2={305.225}
                    y1={62.326}
                    y2={75.992}
                    gradientTransform="translate(-103.313 9.006)"
                    gradientUnits="userSpaceOnUse"
                    z={100}
                />
                <linearGradient
                    xlinkHref="#b"
                    id="j"
                    x1={39.383}
                    x2={39.383}
                    y1={1.398}
                    y2={2.096}
                    gradientTransform="matrix(1.03571 0 0 1.20004 -2.23 -.333)"
                    gradientUnits="userSpaceOnUse"
                    z={100}
                />
                <linearGradient id="b">
                    <stop
                        offset={0}
                        style={{
                            stopColor: "#000",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: "#212121",
                            stopOpacity: 1,
                            zIndex: 100,
                        }}
                    />
                </linearGradient>
                <filter
                    id="g"
                    width={1.009}
                    height={1.004}
                    x={-0.005}
                    y={-0.002}
                    style={{
                        colorInterpolationFilters: "sRGB",
                        zIndex: 100,
                    }}
                >
                    <feGaussianBlur stdDeviation={0.177} />
                </filter>
                <filter
                    id="h"
                    width={1.385}
                    height={1.165}
                    x={-0.192}
                    y={-0.082}
                    style={{
                        colorInterpolationFilters: "sRGB",
                        zIndex: 100,
                    }}
                >
                    <feGaussianBlur stdDeviation={0.031} />
                </filter>
                <filter
                    id="i"
                    width={1.218}
                    height={1.165}
                    x={-0.109}
                    y={-0.082}
                    style={{
                        colorInterpolationFilters: "sRGB",
                        zIndex: 100,
                    }}
                >
                    <feGaussianBlur stdDeviation={0.031} />
                </filter>
            </defs>
            <path
                d="M293.901 62.205h-1.408a.22.22 0 0 0-.221.221v22.66a.22.22 0 0 0 .221.221h1.408a.22.22 0 0 0 .221-.221v-22.66a.22.22 0 0 0-.22-.22z"
                style={{
                    fill: "#6a5f56",
                    fillOpacity: 1,
                    strokeWidth: 0.271631,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M293.914 62.291h-1.433a.208.208 0 0 0-.209.21v22.511c0 .116.093.21.209.21h1.433a.208.208 0 0 0 .208-.21V62.5a.208.208 0 0 0-.208-.209z"
                style={{
                    fill: "url(#c)",
                    fillOpacity: 1,
                    strokeWidth: 0.264137,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M201.691 52.423h-1.408a.22.22 0 0 0-.221.22v13.475a.22.22 0 0 0 .221.22h1.408a.22.22 0 0 0 .221-.22V52.644a.22.22 0 0 0-.221-.221z"
                style={{
                    fill: "#6a5f56",
                    fillOpacity: 1,
                    strokeWidth: 0.271631,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M201.704 52.509h-1.433a.208.208 0 0 0-.21.209v13.326c0 .116.094.209.21.209h1.433a.208.208 0 0 0 .208-.21V52.719a.208.208 0 0 0-.208-.21z"
                style={{
                    fill: "url(#d)",
                    fillOpacity: 1,
                    strokeWidth: 0.264137,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M201.691 37.478h-1.408a.22.22 0 0 0-.221.221v7.839a.22.22 0 0 0 .221.22h1.408a.22.22 0 0 0 .221-.22v-7.839a.22.22 0 0 0-.221-.22z"
                style={{
                    fill: "#6a5f56",
                    fillOpacity: 1,
                    strokeWidth: 0.271631,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M201.704 37.564h-1.433a.208.208 0 0 0-.21.21v7.69c0 .116.094.209.21.209h1.433a.208.208 0 0 0 .208-.21v-7.69a.208.208 0 0 0-.208-.209z"
                style={{
                    fill: "url(#e)",
                    fillOpacity: 1,
                    strokeWidth: 0.264137,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <path
                d="M201.691 71.211h-1.408a.22.22 0 0 0-.221.221v13.474a.22.22 0 0 0 .221.222h1.408a.22.22 0 0 0 .221-.222V71.432a.22.22 0 0 0-.221-.22z"
                style={{
                    fill: "#6a5f56",
                    fillOpacity: 1,
                    strokeWidth: 0.271631,
                    stopColor: "#000",
                    zIndex: 100,
                }}
                transform="translate(-199.996 .05)"
            />
            <rect
                width={92.954}
                height={192.918}
                x={0.619}
                y={0.114}
                ry={15.627}
                style={{
                    fill: "#73675d",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 2.01436,
                    zIndex: 100,
                }}
            />
            <rect
                width={91.931}
                height={191.7}
                x={1.13}
                y={0.723}
                ry={15.362}
                style={{
                    mixBlendMode: "normal",
                    fill: "#e3cebc",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 4.49668,
                    filter: "url(#g)",
                    zIndex: 100,
                }}
            />
            <path
                d="M72.403.114v20.397H.619v1.296h92.954v-1.296H73.699V.114ZM.619 171.338v1.296h19.873v20.398h1.296v-20.398h71.785v-1.296z"
                style={{
                    fill: "#3d332c",
                    fillOpacity: 0.29536402,
                    stroke: "none",
                    strokeWidth: 2.01436,
                    zIndex: 100,
                }}
            />
            <rect
                width={90.405}
                height={190.456}
                x={1.893}
                y={1.345}
                ry={14.837}
                style={{
                    fill: "#111",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 4.9161,
                    zIndex: 100,
                }}
            />
            <rect
                width={87.924}
                height={188.564}
                x={3.134}
                y={2.291}
                ry={14.175}
                style={{
                    fill: "#000",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 1.52333,
                    zIndex: 100,
                }}
            />
            {/* LOT IMAGE */}
            <foreignObject
                x={3.358}
                y={3}
                width={87}
                height={187}
                z={10}
                rotate={90}
                opacity={coverOpacity1}
            >
                <img
                    src={imageSrc}
                    alt="iPhone Screen"
                    className="w-full h-full object-cover rounded-[13px]"
                />
            </foreignObject>
            {/* APP SCREEN */}
            <foreignObject
                x={3.358}
                y={3}
                width={87}
                height={187}
                z={11}
                rotate={90}
                opacity={coverOpacity2}
            >
                <img
                    src={imageSrc2}
                    alt="Map View"
                    className="w-full h-full object-cover rounded-[13px]"
                />
            </foreignObject>
            <g transform="translate(-200.226 .05)">
                <rect
                    width={27.198}
                    height={8.1}
                    x={233.723}
                    y={5.938}
                    ry={4.05}
                    style={{
                        fill: "#000",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 0.264583,
                        strokeDasharray: "none",
                        strokeOpacity: 1,
                        paintOrder: "normal",
                        stopColor: "#000",
                        zIndex: 100,
                    }}
                />
                <g transform="matrix(1.70555 0 0 1.70555 159.184 -13.506)">
                    <circle
                        cx={57.213}
                        cy={13.775}
                        r={1.312}
                        style={{
                            color: "#000",
                            display: "inline",
                            overflow: "visible",
                            visibility: "visible",
                            fill: "#0a0a0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                            strokeWidth: 0.025818,
                            strokeLinecap: "round",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 4,
                            strokeDasharray: "none",
                            strokeDashoffset: 0,
                            strokeOpacity: 1,
                            marker: "none",
                            enableBackground: "accumulate",
                            zIndex: 100,
                        }}
                    />
                    <circle
                        cx={57.213}
                        cy={13.775}
                        r={0.809}
                        style={{
                            color: "#000",
                            display: "inline",
                            overflow: "visible",
                            visibility: "visible",
                            fill: "#0c0e11",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                            strokeWidth: 0.0198752,
                            strokeLinecap: "round",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 4,
                            strokeDasharray: "none",
                            strokeDashoffset: 0,
                            strokeOpacity: 1,
                            marker: "none",
                            enableBackground: "accumulate",
                            zIndex: 100,
                        }}
                    />
                    <circle
                        cx={57.213}
                        cy={13.775}
                        r={0.617}
                        style={{
                            color: "#000",
                            display: "inline",
                            overflow: "visible",
                            visibility: "visible",
                            fill: "#12171e",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                            strokeWidth: 0.0198752,
                            strokeLinecap: "round",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 4,
                            strokeDasharray: "none",
                            strokeDashoffset: 0,
                            strokeOpacity: 1,
                            marker: "none",
                            enableBackground: "accumulate",
                            zIndex: 100,
                        }}
                    />
                    <path
                        d="M-47.128 5.43c-.098.01-.236.16-.246.451-.01.292.117.451.19.451.07 0 .368-.425.056-.902z"
                        style={{
                            display: "inline",
                            fill: "#425a72",
                            fillOpacity: 0.299338,
                            fillRule: "evenodd",
                            stroke: "none",
                            strokeWidth: 0.0264583,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 4,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                            filter: "url(#h)",
                            zIndex: 100,
                        }}
                        transform="matrix(.75119 0 0 .75119 92.191 9.283)"
                    />
                    <path
                        d="M-46.43 5.328c-.153.047-.182.295-.177.437.006.142.207.514.45.431.241-.083.283-.39.182-.596-.1-.207-.283-.355-.454-.272z"
                        style={{
                            display: "inline",
                            fill: "#425a72",
                            fillOpacity: 0.299338,
                            fillRule: "evenodd",
                            stroke: "none",
                            strokeWidth: 0.0264583,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 4,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                            filter: "url(#i)",
                            zIndex: 100,
                        }}
                        transform="matrix(.75119 0 0 .75119 92.191 9.283)"
                    />
                </g>
            </g>
            <path
                d="M36.464 1.345c1.09 0 1.061.26 1.287.554.164.215.376.284.793.284h17.104c.417 0 .628-.069.793-.284.225-.294.197-.554 1.286-.554z"
                style={{
                    fill: "url(#j)",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: ".294971px",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeOpacity: 1,
                    zIndex: 100,
                }}
            />

            {/* The image will be placed here */}

        </svg>
    )
}
export default IPhoneFrame