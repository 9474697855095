import React from "react";
import "./ViolationCard.css";

const TitleCard = ({ violation }) => {
    const cdnUrl = "https://cdn.spotparking.app/public/site/";
    // Map vehicle types to their respective icon file names
    const vehicleTypeIcons = {
        car: `${cdnUrl}vehicleIcons/sedan.png`,
        bike: `${cdnUrl}vehicleIcons/bike.png`,
        truck: `${cdnUrl}vehicleIcons/truck.png`,
        suv: `${cdnUrl}vehicleIcons/suv.png`,
    };

    // Function to get the icon based on the vehicle type
    const getVehicleIcon = (VehicleType) => {
        if (vehicleTypeIcons[VehicleType]) {
            return <img src={vehicleTypeIcons[VehicleType]} alt={VehicleType} />;
        }
        return (
            <img src={`../../assets/vehicleIcons/sedan.png`} alt="Default Icon" />
        );
    };

    const getRandomTime = () => {
        const hours = Math.floor(Math.random() * 24);
        const minutes = Math.floor(Math.random() * 60);
        return new Date().setHours(hours, minutes);
    };

    const timeParked = getRandomTime();
    const timeParkedActual = new Date(timeParked).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const getTimeSinceParked = () => {
        const currentTime = new Date();
        const timeDifference = currentTime - timeParked;
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        return `${Math.abs(hours)}h ${Math.abs(minutes)}m`;
    };

    const timeSinceParked = getTimeSinceParked();
    // Function to determine if there is a violation or not
    function violationBoolean() {
        if (violation.ViolationType === "N/A") {
            return (
                null
            )
        } else {
            return (
                <p>Violation: {violation.ViolationType}</p>
            )
        }
    }

    return (
        <div>
            <div className="violation-header font-rubik">
                <div className="flex flex-row gap-6 items-center mr-10">
                    <div className="w-1/3">
                        {getVehicleIcon(violation.VehicleType)}
                    </div>
                    <div className="flex flex-col justify-start text-2xl">
                        <p className=""><strong>Spot:</strong> {violation.Spot}</p>
                        <p className=""><strong>Plate #:</strong> {violation.LicensePlate}</p>
                    </div>
                </div>
            </div>
            <div className=" font-rubik text-xl p-4">
                {violationBoolean()}
                <p>Parked Since: {timeParkedActual}</p>
                <p>Parked for: {timeSinceParked}</p>
            </div>
        </div>
    );
};

export default TitleCard;
