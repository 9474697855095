import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Footer } from "../Footer";
import { FaDollarSign } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaArrowRight, FaQuoteRight } from "react-icons/fa6";
import { HiOutlineChartBar } from "react-icons/hi2";
import { PiPuzzlePiece, PiSecurityCamera } from "react-icons/pi";
import Arrow from "../Arrow";
import { Helmet } from "react-helmet-async";
import emailjs from "emailjs-com";
import { Faq } from "../Faq";

export const Home = () => {
    const wide = window.innerWidth > 1080
    const [scrollPosition, setScrollPosition] = useState(0);
    const cdnUrl = "https://cdn.spotparking.app/public/site/";
    const radius = Math.round((scrollPosition * 100));
    const width = 110 - (scrollPosition * 10);
    const smallWidth = (scrollPosition * 8) + 80;
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [submitMessage, setSubmitMessage] = useState("");

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Please enter a valid email address.");
            return false;
        } else {
            setEmailError("");
            return true;
        }
    };

    const handleEmailChange = (e) => {
        setEmailError("");
        setEmail(e.target.value);
    };

    const sendEmail = () => {
        if (!email) {
            toast.error("Make sure to fill out all the fields!");
            return;
        }

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        };

        setSubmitMessage("Message has been sent!");

        const templateParams = {
            name: email,
            email,
            phone: "",
            message: "I'm looking to learn more!"
        };

        // Send email using emailjs
        emailjs
            .send(
                "service_dsu84xg",
                "template_ho732mv",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });

        // Reset form fields
        setEmail("");

        setTimeout(() => {
            setSubmitMessage("");
        }, 2000);
    };


    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex justify-center items-center flex-col h-auto w-full overflow-clip bg-white font-rubik">
            <Helmet>
                <title>Spot Parking | Home</title>
                <meta name="description" content="Spot Parking offers efficient, automated parking enforcement to universities, increasing revenue and freeing up to 27% more parking spaces." />
                <link rel="canonical" href="https://spotparking.app" />
            </Helmet>

            {/* SECTION ONE */}
            <div className={`flex flex-col gap-10 h-screen w-full bg-white overflow-hidden justify-center items-center select-none`}>
                <h1 className="z-10 w-full text-white lg:text-9xl text-shadow-lg md:text-8xl sm:text-7xl text-4xl font-stretched font-bold transition-all text-center">Parking Solutions <br /> Automated</h1>
                <Link to="/solutions" className="z-50">
                    <button className="bg-gradient-to-r from-spotYellow shadow-md p-2 px-10 rounded-lg sm:text-3xl rubik-bold text-spotGray to-yellow-300 lg:top-[600px] md:top-[500px] sm:top-[430px] top-[300px] left-12 xl:left-36 yellow-gradient hover:scale-105 hover:shadow-lg transition-all duration-200">
                        Learn More
                    </button>
                </Link>
                <img src={`${cdnUrl}darkGarage.jpg`} alt="Phone render" className="absolute w-screen h-screen left-1/2 -translate-x-1/2 top-0 object-cover" style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }} />
                <Arrow />
            </div>

            <div className="flex flex-col lg:flex-row gap-10 justify-start items-start p-4 py-16 w-[90%] bg-white" style={{ width: smallWidth + "%" }}>
                {[
                    {
                        imgSrc: `${cdnUrl}lotVertical.png`,
                        title: "Capacity Monitoring",
                        description: "Track who is coming in and out of your parking lot at all times. Help users find the nearest open parking spot.",
                        link: "/capacityMonitoring"
                    },
                    {
                        imgSrc: `${cdnUrl}meteredSpots.png`,
                        title: "Digital Gate",
                        description: "Hands free metered parking. No more need for customers needing to find quarters or worry about if their parking metered has expired.",
                        link: "/digitalGate"
                    },
                    {
                        imgSrc: `${cdnUrl}carScan.png`,
                        title: "Automated Enforcement",
                        description: "Automate your parking enforcement process. From vehicle detection to issuing the parking citation.",
                        link: "/automatedEnforcement"
                    }
                ].map((item, index) => (
                    <div key={index} className="flex flex-col gap-5 justify-center items-center w-full lg:w-1/3 transition-all duration-300">
                        <Link to={item.link}>
                            <img src={item.imgSrc} alt="Phone render" className="w-full h-96 fcc object-cover rounded-xl hover:scale-105 hover:shadow-lg transition-all duration-200" draggable={false} />
                        </Link>
                        <h2 className="font-bold text-3xl text-center">{item.title}</h2>
                        <p className="w-[80%] text-center">{item.description}</p>
                        <Link to={item.link}>
                            <button className="bg-gradient-to-r from-spotYellow to-yellow-200 flex flex-row gap-2 hover:scale-105 transition-all duration-100 p-2 px-4 rounded-xl shadow-xl font-bold">
                                Learn More
                                <FaArrowRight className="self-center drop-shadow-lg" />
                            </button>
                        </Link>
                    </div>
                ))}
            </div>

            {/* SHOW OFF GRAY SECTION */}
            <div className="flex flex-col gap-10 justify-center items-center h-fit z-20 bg-lightYellow p-20 shadow-xl w-screen">
                <h2 className="font-bold text-4xl lg:text-6xl text-center">Why Spot Parking?</h2>
                <div className="h-3 w-40 bg-spotYellow" />
                <div className="flex flex-col lg:flex-row gap-20 justify-center items-center w-fit">
                    {[
                        {
                            icon: <FaRegClock size={50} />,
                            title: "Cost Efficient"
                        },
                        {
                            icon: <PiSecurityCamera size={50} />,
                            title: "Improved Security"
                        },
                        {
                            icon: <FaDollarSign className="rounded-full p-1 border-2 border-spotGray" size={50} />,
                            title: "Cost Efficient"
                        },
                        {
                            icon: <HiOutlineChartBar size={50} />,
                            title: "Statistical Analysis"
                        },
                        {
                            icon: <PiPuzzlePiece size={50} />,
                            title: "Customizable Integration"
                        },
                    ].map((item, index) => (
                        <div key={index} className="flex flex-col gap-4 justify-center items-center">
                            <div className="feature-icon-container text-spotGray text-3xl group hover:scale-105 w-fit rounded-xl transition-all duration-300 ease-in-out">
                                <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out ">
                                    {item.icon}
                                </div>
                            </div>
                            <h3 className="font-bold text-center font-rubik">{item.title}</h3>
                        </div>
                    ))}
                </div>
            </div>

            {/* Testimonials */}
            <div className="w-full bg-spotGray p-20 flex flex-col gap-8 justify-center items-center">
                <h2 className={`font-bold text-4xl sm:text-5xl lg:text-7xl text-white text-center`}>Testimonials</h2>
                <div className="flex flex-col lg:flex-row gap-10 justify-center items-start p-4 w-screen lg:w-[90%] mt-4">
                    {[
                        {
                            university: "Eastern Washington University",
                            quote: "I love the idea! Recently, we've had multiple car break-ins, and having the Spot Parking system would have been very helpful in improving campus security.",
                        },
                        {
                            university: "Boise State University",
                            quote: "I love the innovation and practicality, and especially the efficient way to monitor unused parking spots and direct students to them.",
                        },
                        {
                            university: "Westmont University",
                            quote: "If we had a system that could automatically track people’s license plates and tell us if they need to be ticketed… That would make our life so much easier.",
                        }
                    ].map((currentTestimonial, index) => (
                        <div key={index} className="flex flex-row lg:flex-col gap-5 justify-center items-start lg:items-center w-full px-2 lg:w-1/3 transition-all duration-300">
                            <FaQuoteRight size={80} className="text-white p-3 border-2 border-white rounded-full" />
                            <div className="flex flex-col gap-5 justify-center items-start lg:items-center w-full">
                                <p className="w-full lg:w-[80%] text-left lg:text-center text-white">"{currentTestimonial.quote}"</p>
                                <h2 className="text-md lg:text-2xl text-center text-white">- {currentTestimonial.university}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-white flex flex-col lg:flex-row justify-center items-start gap-8 lg:gap-20 p-10 py-24 w-full 2xl:max-w-[60%]">
                <h2 className="font-semibold text-4xl md:text-4xl lg:text-5xl text-spotGray select-none min-w-fit">Have questions?</h2>
                <Faq width={"w-full"} />
            </div>

            {/* INTERESTED? */}
            <div className='text-4xl md:text-8xl font-</div>bold relative border-t-8 border-t-spotGray flex flex-col gap-10 text-center text-spotGray bg-spotYellow w-screen justify-center overflow-hidden p-8 md:p-12'>
                <div>
                    <h2 className="text-4xl md:text-8xl w-full text-left text-spotGray font-semibold">Interested?</h2>
                    <h2 className="text-xl md:text-2xl w-full text-left text-spotGray font-semibold">Discover how Spot Parking can transform your campus parking</h2>
                </div>
                <div className='flex flex-col md:flex-row md:justify-start gap-5 items-center'>
                    <div className='flex flex-row w-full md:w-fit'>
                        <input
                            type='text'
                            placeholder='Email Address'
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={() => validateEmail(email)}
                            className='w-full md:w-48 lg:w-96 h-12 md:h-16 rounded-l-xl text-xl md:text-2xl font-semibold text-spotGray p-4 outline-none'
                        />
                        <div onClick={sendEmail} className={`h-12 md:h-16 ${wide ? '' : 'w-[50%]'} px-2 md:px-6 font-bold text-spotYellow text-lg sm:text-2xl bg-spotGray rounded-r-xl border-2 border-spotGray transition-all hover:scale-105 hover:rounded-l-sm cursor-pointer duration-100 flex justify-center items-center`}>Learn More</div>
                    </div>
                    {window.innerWidth > 760 ?
                        <div className='w-[2px] h-12 md:h-16 border-l-1 bg-spotGray' /> :
                        <div className='w-full flex flex-row h-4 justify-center items-center gap-4'>
                            <div className='w-full h-[2px] bg-spotGray' />
                            <p className='text-spotGray text-3xl'>or</p>
                            <div className='w-full h-[2px] bg-spotGray' />
                        </div>
                    }
                    <Link to='/contact'>
                        <div className='h-12 md:h-16 px-4 lg:px-6 w-full lg:w-64 font-bold text-spotGray text-xl lg:text-2xl bg-transparent rounded-xl border-2 border-spotGray transition-all hover:bg-spotGray hover:scale-105 hover:text-spotYellow cursor-pointer duration-100 flex justify-center items-center'>Request a Demo</div>
                    </Link>
                </div>
                {emailError && <p className='text-red-500 text-lg font-semibold'>{emailError}</p>}
                {submitMessage && <p className='text-spotGray text-lg font-semibold'>{submitMessage}</p>}
            </div>

            <div className="flex-col-center w-screen">
                <Footer textColor={"#323232"} bg={'#ffe374'} />
            </div>
        </div>
    )
};