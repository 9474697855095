import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { IoIosTimer } from "react-icons/io";
// import BarLoader from "react-spinners/BarLoader";
import { Sheet } from 'react-modal-sheet';
import { TbHandFinger } from "react-icons/tb";
import { FaXmark } from "react-icons/fa6";
import { IoMdInformation } from "react-icons/io";
import concaveman from "concaveman";

export const WebMap = () => {
    const [listData, setListData] = useState([]);
    const [lineData, setLineData] = useState([]);
    const [darkMode, setDarkMode] = useState(false);
    const [allPermits, setAllPermits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const cdnUrl = "https://cdn.spotparking.app/public/site/";
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
        try {
            paramsObject[key] = JSON.parse(value);
        } catch (e) {
            paramsObject[key] = value;
        }
    });

    if (!paramsObject.coords) {
        window.location.href = "/webmapPicker";
    };

    const startSpot = paramsObject.coords ? paramsObject.coords : [-111, 40];
    const colorScheme = darkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v10"

    useEffect(() => {
        setListData(JSON.parse(sessionStorage.getItem("spots")));
        setLineData(JSON.parse(sessionStorage.getItem("lines")));
        setAllPermits(JSON.parse(sessionStorage.getItem("permitTypes")));
    }, []);

    // Map Object
    useEffect(() => {
        const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
        setDarkMode(matchMedia.matches);

        const handleChange = (e) => setDarkMode(e.matches);
        matchMedia.addEventListener("change", handleChange);

        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpot,
            zoom: paramsObject?.zoom ? paramsObject?.zoom : 15,
            attributionControl: false,
            dragRotate: true,
            scrollZoom: true,
            maxPitch: 0,
        });

        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            await addAllPolygons(newMap);
            await addLines(newMap);
        });

        // Disable pointer cursor
        newMap.getCanvas().style.cursor = "default";

        // Add user location control
        newMap.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
                showUserHeading: true,
            }),
            "top-right"
        );

        newMap.addControl(new mapboxgl.NavigationControl(), "top-right");

        // Add line cursor
        newMap.on("mousemove", (e) => {
            const features = newMap.queryRenderedFeatures(e.point);
            const polygonFeatures = features.filter((feature) => feature.layer.type === "fill");
            newMap.getCanvas().style.cursor = polygonFeatures.length ? "pointer" : "default";
        });

        return () => {
            matchMedia.removeEventListener("change", handleChange);
            newMap.remove();
        };
        // eslint-disable-next-line
    }, [listData, lineData]);

    const [lots, setLots] = useState({
        individualLots: {},
        permits: {}
    });

    const addAllPolygons = async (map, permit = "") => {
        const occupied = darkMode ? "#808080" : "#C9C9C9"
        const coordinatesArray = listData.map((item) => [
            JSON.parse(item.coordinates),
            item.licensePlate,
            item.lot,
            item.spot,
            item.permitType,
            item.on
        ]);

        let uncoveredSpots = [];

        // Build the FeatureCollection
        const features = coordinatesArray.map(([coordinates, licensePlate, lot, spot, permitType, on]) => {
            if (on === "false") {
                uncoveredSpots.push([
                    coordinates,
                    licensePlate,
                    lot,
                    spot,
                    permitType,
                    on
                ]);
            }

            let fillColor;
            const permitColor = allPermits.find((permit) => permit.permitType === permitType)?.color ?? occupied;

            if (on === "false") {
                fillColor = "transparent";
            } else if (permit !== "" && licensePlate === "" && permitType === permit) {
                fillColor = permitColor;
            } else if (permit === "" && licensePlate === "") {
                fillColor = permitColor;
            } else {
                fillColor = occupied;
            };

            return {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [coordinates]
                },
                properties: {
                    id: `polygon-layer-${licensePlate}`,
                    licensePlate: licensePlate,
                    spot: spot,
                    lot: lot,
                    coordinates: coordinates,
                    fillColor: fillColor
                }
            };
        });

        const featureCollection = {
            type: "FeatureCollection",
            features: features
        };

        const exedSpots = uncoveredSpots.map(([coordinates, lot, spot, spotID, on, permitType, orgID]) => {
            return {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [coordinates[0], coordinates[2], coordinates[3], coordinates[1], coordinates[0]]
                },
                properties: {
                    id: `line-layer-${spotID}`,
                    spot,
                    lot,
                    fillColor: darkMode ? '#888' : '#ddd',
                    spotID,
                    // level,
                    on,
                    permitType,
                    orgID,
                }
            };
        });

        const exFeatureCollection = {
            type: "FeatureCollection",
            features: exedSpots
        };

        const tempLots = {
            individualLots: {},
            permits: {}
        };

        for (let i in listData) {
            const spot = listData[i];
            if (!tempLots.individualLots[spot.lot]) {
                tempLots.individualLots[spot.lot] = [];
                tempLots.permits[spot.lot] = new Set();
            };
            tempLots.individualLots[spot.lot].push(JSON.parse(spot.coordinates)[0]);
            spot.permitType && tempLots.permits[spot.lot].add(spot.permitType);
        };

        setLots(tempLots);
        const ALPHA = 15;

        // Create hulls for each lot
        const hulls = {};
        Object.keys(tempLots.individualLots).forEach((lot) => {
            const lotArray = tempLots.individualLots[lot];
            const hull = concaveman(lotArray, ALPHA);
            hulls[lot] = {};
            hulls[lot].coordinates = hull;
            hulls[lot].lot = lot;
        });

        // Create border polygons
        const borders = Object.keys(hulls).map((lot) => {
            const coordinates = hulls[lot].coordinates;

            return {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [coordinates.map((coord) => [coord[0], coord[1]])]
                },
                properties: {
                    id: `border-layer-${lot}`,
                    lot,
                    fillColor: "#ffe374"
                }
            };
        });

        const borderFeatureCollection = {
            type: "FeatureCollection",
            features: borders
        };

        // Add the FeatureCollection to the map
        if (map.getSource('polygons')) {
            map.getSource('polygons').setData(featureCollection);
        } else {
            // Add On Spots
            map.addSource('polygons', {
                type: 'geojson',
                data: featureCollection,
            });
            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygons',
                minzoom: 16,
                paint: {
                    'fill-color': ['get', 'fillColor'],
                    'fill-opacity': 1,
                    'fill-outline-color': 'transparent',
                }
            });

            // Add Off spots
            map.addSource('exedSpots', {
                type: 'geojson',
                data: exFeatureCollection
            });
            map.addLayer({
                id: 'exed-layer',
                type: 'line',
                source: 'exedSpots',
                minzoom: 16,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': ['get', 'fillColor'],
                    "line-width": {
                        "type": "exponential",
                        "base": 2,
                        "stops": [
                            [0, 1.3 * Math.pow(2, (0 - 18))],
                            [24, 1.3 * Math.pow(2, (24 - 18))]
                        ]
                    }
                }
            })

            // Add Border polygons
            map.addSource('borders', {
                type: 'geojson',
                data: borderFeatureCollection
            });
            map.addLayer({
                id: 'border-layer',
                type: 'fill',
                source: 'borders',
                maxzoom: 16,
                paint: {
                    'fill-color': ['get', 'fillColor'],
                    'fill-opacity': 0.7,
                    'fill-outline-color': 'transparent',
                }
            });

            // Add click event for border polygons
            map.on('click', 'border-layer', (e) => {
                console.log('Border clicked:', e.features[0].properties);
            });
            map.addLayer({
                id: 'border-text-layer',
                type: 'symbol',
                source: 'borders',
                maxzoom: 16,
                layout: {
                    'text-field': ['get', 'lot'],
                    'text-size': 20,
                    'text-offset': [0, 0],
                    'text-anchor': 'center',
                },
                paint: {
                    'text-color': darkMode ? '#fff' : '#000',
                    'text-halo-color': darkMode ? '#000' : '#fff',
                    'text-halo-width': 2,
                    "text-halo-blur": 1
                }
            });
        };
    };

    function jsonBuilder(arr) {
        const finalJson = [];
        // const currentLevel = "0"; // Define or pass current level

        arr.forEach((lot) => {
            for (let i in lot) {
                // const level = lot[i].level;
                const lines = lot[i].lines;
                // if (currentLevel.toString() === level) {
                for (let j in lines) {
                    finalJson.push({
                        type: "Feature",
                        properties: {},
                        geometry: {
                            coordinates: lines[j],
                            type: "LineString"
                        }
                    });
                }
                // }
            }
        });

        return finalJson;
    }

    //Add lines to the map. Gets its data from the jsonBuilder function 
    const addLines = async (map) => {
        map.addSource('route', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": jsonBuilder(lineData)
            }
        });

        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'square',
                'line-sort-key': 10
            },
            paint: {
                'line-color': '#ffe374',
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [0, 1.3 * Math.pow(2, (0 - 18))],
                        [24, 1.3 * Math.pow(2, (24 - 18))]
                    ]
                }
            },
            minzoom: 16
        });
        setLoading(false);
    };

    const bottomSheetRef = useRef();

    const openModal = () => {
        bottomSheetRef.current.snapTo(0);
        setOpen(true);
    };

    const [selectedPermit, setSelectedPermit] = useState(null);

    const selectPermit = (permit) => {
        if (selectedPermit === permit) {
            setSelectedPermit(null);
            addAllPolygons(map);
            return;
        }
        setSelectedPermit(permit);
        addAllPolygons(map, permit.permitType);
    }

    const SinglePermit = ({ permitType }) => {
        const currentPermit = selectedPermit?.permitType ?? "";
        const permitData = allPermits.find((permit) => permit.permitType === permitType);
        const permitColor = permitData?.color ?? "#808080";
        const permitName = permitData?.description ?? "Unknown Permit";

        return (
            <div onClick={() => selectPermit(permitData)} className={`flex flex-row gap-2 justify-center items-center bg-white rounded-lg shadow-lg p-2`}
                style={{
                    outline: permitType === currentPermit ? `4px solid ${permitColor}` : "3px solid #E0E0DE",
                    outlineOffset: "-3px",
                }}
            >
                <div className="w-5 h-5 rounded-full" style={{ backgroundColor: permitColor }} />
                <p className="text-nowrap">{permitName} ({permitType})</p>
            </div>
        );
    };

    return (
        <div id="container" className="h-screen w-screen overflow-hidden">
            <div id="map-container" className="relative h-[110%] w-screen top-0 left-0">
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>

            {/* INFO BUTTON */}
            <button className={`z-50 absolute top-2 left-2 bg-white rounded-md border-2 border-[#E0E0DE] shadow-lg active:bg-gray-200`} onClick={openModal}>
                <IoMdInformation size={28} color="#323232" />
            </button>

            {/* PERMITS */}
            <div className="absolute bottom-4 left-0 w-screen overflow-scroll flex flex-row gap-2 justify-start items-center no-scrollbar pl-4">
                {allPermits.map((permit, index) => (
                    <SinglePermit key={index} permitType={permit.permitType} />
                ))}
            </div>

            {/* INFO MODAL */}
            <Sheet
                ref={bottomSheetRef}
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                detent="content-height"
                rootId="container"
            >
                <Sheet.Container style={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    backgroundColor: darkMode ? "#323232" : "#fff",
                }}>
                    <Sheet.Header style={{
                        backgroundColor: darkMode ? "#323232" : "#fff",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                    }} />
                    <Sheet.Content className={`${darkMode ? 'bg-spotGray' : 'bg-white'} w-full p-8 pt-0 justify-start items-center`}>
                        <div className={`flex flex-col gap-5 w-full lg:w-[40%] font-rubik justify-center items-center ${darkMode ? 'text-white' : 'text-black'}`}>
                            <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>About the Map</h2>
                            <div className="flex flex-col justify-center items-start gap-5">
                                <div className="flex justify-center items-center flex-row gap-2">
                                    <IoIosTimer size={34} />
                                    <p>Updates in real time</p>
                                </div>
                                <div className="flex justify-center items-center flex-row gap-2">
                                    <TbHandFinger size={34} />
                                    <p>Long press to navigate</p>
                                </div>
                            </div>
                            <div className="flex flex-row justify-center items-center gap-5">
                                <div className={`px-4 py-2 flex justify-center items-center border min-w-fit ${darkMode ? 'bg-[#555] border-[#666]' : 'bg-white'} shadow-lg rounded-xl`}>
                                    ABC Visitor (V)
                                </div>
                                <p className="text-center">Tap to filter by spot type</p>
                            </div>
                            <div className="flex flex-row w-full justify-between items-center gap-5">
                                <div className="flex justify-center items-center flex-col gap-2 w-96">
                                    <img src={`${cdnUrl}triColor.png`} alt="tri-color" className="w-auto h-10" />
                                    <p className="text-center">Open</p>
                                </div>
                                <div className="flex justify-center items-center flex-col gap-2 w-96">
                                    <div className="w-10 h-10 bg-gray-400 rounded-full" />
                                    <p className="text-center">Taken</p>
                                </div>
                                <div className="flex justify-center items-center flex-col gap-2 w-96 -mt-2">
                                    <FaXmark size={60} color="#C0C2C6" />
                                    <p className="text-center -mt-2 text-nowrap">No Cameras</p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-5">
                                <h2 className="font-bold text-2xl w-full text-center">Map Style</h2>
                                <div className="flex flex-row gap-5 justify-center items-center">
                                    <img src={`${cdnUrl}darkMap.png`} alt="dark-mode" className={`w-28 h-28 rounded-lg border-4 ${darkMode ? "border-[#777]" : "border-gray-400"}`} />
                                    <img src={`${cdnUrl}lightMap.png`} alt="light-mode" className={`w-28 h-28 rounded-lg border-4 ${darkMode ? "border-[#777]" : "border-gray-400"}`} />
                                    <img src={`${cdnUrl}satelliteMap.png`} alt="satellite-mode" className={`w-28 h-28 rounded-lg border-4 ${darkMode ? "border-[#777]" : "border-gray-400"}`} />
                                </div>
                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => setOpen(false)} />
            </Sheet>
        </div >
    );
};


{/* LOT MODAL */ }
{/* <div className="flex flex-col gap-2 justify-start items-center absolute bottom-24 left-4 border w-fit bg-white shadow-lg rounded-xl p-4">
                <div className="flex flex-col items-center">
                    <div className="flex relative h-10">
                        {clickedLot &&
                            Array.from(lots.permits[clickedLot] || []).map((permitLetter, index) => {
                                const responseColor = permitTypes[0].permitTypes.find(
                                    (permit) => permit.permitType === permitLetter
                                )?.color;
                                if (responseColor) {
                                    startingValue += 1;
                                    return (
                                        <div
                                            key={index}
                                            className="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold absolute"
                                            style={{
                                                backgroundColor: responseColor,
                                                left: `${35 * startingValue - 35}px`,
                                            }}
                                        >
                                            {listData?.filter(
                                                (item) =>
                                                    item.lot === clickedLot &&
                                                    item.licensePlate === "" &&
                                                    item.permitType === permitLetter
                                            ).length}
                                        </div>
                                    );
                                }
                            })}
                    </div>

                    <h1 className={`text-lg font-bold mt-7 ${darkMode ? "text-white" : "text-gray-900"}`}>
                        Lot {clickedLot} (
                        {lotData?.find((lot) => lot.lotNum === clickedLot)?.lotName || "Unknown"})
                    </h1>
                </div>
                <p className={`text-sm mt-2 ${darkMode ? "text-white" : "text-gray-800"}`}>
                    Enforced: Monday-Friday
                </p>
                <p className={`text-sm mt-2 ${darkMode ? "text-white" : "text-gray-800"}`}>
                    Closed: 1AM-5AM
                </p>
            </div> */}