import React, { useState } from "react";
import { FaPlus } from 'react-icons/fa';

export const Faq = ({ width }) => {
    const questions = [
        { question: "How do I learn more about Spot Parking?", answer: "Spot Parking is an automated parking enforcement solution." },
        { question: "How do I create an account?", answer: "It uses cameras and sensors to monitor parking spaces and enforce rules." },
        { question: "How accurate is the object detection software?", answer: "Yes, it helps reduce costs by automating the enforcement process." },
        { question: "Can I demo a Spot Parking solution?", answer: "Yes, it helps reduce costs by automating the enforcement process." }
    ];

    const [expandedQuestion, setExpandedQuestion] = useState(null);

    const toggleQuestion = (index) => {
        setExpandedQuestion(expandedQuestion === index ? null : index);
    };

    return (
        <div className={width}>
            {questions.map((item, index) => (
                <div key={index} className="border-b border-gray-300 py-2 select-none">
                    <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => toggleQuestion(index)}
                    >
                        <h3 className="text-xl font-semibold">{item.question}</h3>
                        <FaPlus className={`transition-transform ${expandedQuestion === index ? 'rotate-45' : ''}`} />
                    </div>
                    {expandedQuestion === index && (
                        <p className="mt-2 text-gray-700 w-96 lg:w-full">{item.answer} {item.question === "Can I demo a Spot Parking solution?" && <a href="/contact" className="underline">Contact Us Here</a>}</p>
                    )}
                </div>
            ))}
        </div>
    );
};