import React from 'react';
import carGif from '../../assets/car.gif';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-white">
            <h1 className="text-6xl font-bold mb-4">404</h1>
            <p className="text-2xl mb-8">Oops! We couldn't find that page.</p>
            <p className="text-lg mb-8">Looks like you can't park here!</p>
            <a href="/" className="px-4 py-2 yellow-gradient font-bold text-spotGray rounded hover:scale-110 transition-all duration-200">
                Go Back Home
            </a>
            <img src={carGif} alt="Car Gif" className="w-[2300px] h-[1000px] ml-96 absolute object-contain" />
        </div>
    );
};

export default NotFound;