import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles.css';
import { Home } from './components/pages/Home';
import { AutomatedEnforcement } from './components/pages/AutomatedEnforcement';
import { ContactUs } from './components/pages/ContactUs';
import { Calculator } from './components/pages/Calculator';
import { NavBar } from './components/NavBar';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions';
import Download from './components/pages/Download';
import { DigitalGate } from './components/pages/DigitalGate';
import { Solutions } from './components/pages/Solutions';
import Accessibility from './components/pages/Accessibility';
import { CapacityMonitoring } from './components/pages/CapacityMonitoring';
import { WebMap } from './components/pages/WebMap';
import NotFound from './components/pages/NotFound';
import SignHandler from './components/pages/SignHandler';
import News from './components/pages/News';
import WebMapPicker from './components/pages/WebMapPicker';

function App() {
    const [state, setState] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setState(state + 1);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [state]);

    const mainPages = [
        { path: '/', component: <Home /> },
        { path: '/solutions', component: <Solutions /> },
        { path: '/capacityMonitoring', component: <CapacityMonitoring /> },
        { path: '/digitalGate', component: <DigitalGate /> },
        { path: '/automatedEnforcement', component: <AutomatedEnforcement /> },
        { path: '/contact', component: <ContactUs /> },
        { path: '/news', component: <News /> },
    ];

    const infoPages = [
        { path: '/privacy-policy', component: <PrivacyPolicy /> },
        { path: '/terms-and-conditions', component: <TermsAndConditions /> },
        { path: '/accessibility', component: <Accessibility /> },
    ];

    const miscPages = [
        { path: '/calculator', component: <Calculator /> },
        { path: '/download', component: <Download /> },
        { path: '/signHandler', component: <SignHandler /> },
        { path: '/webmap', component: <WebMap /> },
        { path: '/webmapPicker', component: <WebMapPicker /> },
        { path: '*', component: <NotFound /> },
    ];

    const routes = [
        ...mainPages,
        ...infoPages,
        ...miscPages,
    ];

    const noNavBarList = [
        '/webmap',
        '/download',
        '/webmapPicker',
        '/signHandler',
    ];

    return (
        <Router>
            {!noNavBarList.some(path => window.location.pathname.includes(path)) && <NavBar />}
            <Routes>
                {routes.map(({ path, component }) => (
                    <Route key={path} path={path} element={component} />
                ))}
            </Routes>
        </Router>
    );
}

export default App;