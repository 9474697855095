import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import IPhoneFrame from '../../assets/IPhoneFrame';
import { useState, useEffect } from 'react';
import SplitType from "split-type";
import StaticIPhoneFrame from '../../assets/StaticIPhoneFrame';
import { Footer } from '../Footer';
import lot from "../../assets/images/lot.png";
import lotVertical from "../../assets/images/lotVertical.png";

export const CapacityMonitoring = () => {
    const wide = window.innerWidth > 1080
    const [rotate, setRotate] = useState(wide ? 90 : 0);
    const [scale, setScale] = useState(window.innerWidth > 760 ? 8 : 5);
    const [coverOpacity1, setOpacity1] = useState(.3);
    const [coverOpacity2, setOpacity2] = useState(0);
    const [onTheGoOpacity, setOnTheGoOpacity] = useState(1);
    const [automated, setAutomated] = useState(false);
    const [secondaryPhoneOpacity, setSecondaryPhoneOpacity] = useState(0);
    const [secondaryPhoneY, setSecondaryPhoneY] = useState(0);
    const [spotNavOpacity, setSpotNavOpacity] = useState(0);
    const [primaryPhoneOpacity, setPrimaryPhoneOpacity] = useState(1);
    const cdnUrl = "https://cdn.spotparking.app/public/site/";

    function ease(value) {
        return value * value * (3 - 2 * value);
    }

    const settings = {
        rotation: {
            scrollStart: 2000,
            scrollSpeed: 2000,
            endValue: 0,
            startValue: 90
        },
        scale: {
            scrollSpeed: 1000,
            endValue: 1,
            startValue: window.innerWidth > 760 ? 8 : 5
        },
        lotOpacity: {
            scrollSpeed: 1000,
            endValue: 1,
            startValue: 0.3
        },
        appOpacity: {
            scrollStart: 2500,
            scrollSpeed: 2000,
            endValue: 0,
            startValue: 90
        },
        onTheGoOpacity: {
            scrollStart: 2500,
            scrollSpeed: 2000,
        },
        secondaryPhoneOpacity: {
            scrollStart: 4000,
            leaveStart: wide ? 9800 : 8000,
            scrollSpeed: 2000,
        },
        secondaryPhoneY: {
            scrollStart: 4000,
            scrollSpeed: 2000,
        },
        spotNavOpacity: {
            scrollStart: 5000,
            scrollSpeed: 2000,
        }
    };

    const handleScroll = () => {
        // ROTATION
        if (wide) {
            if (window.scrollY >= settings.rotation.scrollStart) {
                const t = Math.max(settings.rotation.endValue, Math.min(1, (window.scrollY - settings.rotation.scrollStart) / settings.rotation.scrollSpeed));
                setRotate(Math.max(settings.rotation.endValue, 90 - ease(t) * 90));
            } else {
                setRotate(settings.rotation.startValue);
            };
        };

        // SCALE
        const scaleT = Math.max(0, Math.min(1, window.scrollY / settings.scale.scrollSpeed));
        setScale(Math.max(1, settings.scale.startValue - ease(scaleT) * (window.innerWidth > 760 ? 9.5 : 4.5)));

        // LOT OPACITY
        const lotOpacityT = Math.max(0, Math.min(settings.lotOpacity.endValue, window.scrollY / settings.lotOpacity.scrollSpeed));
        setOpacity1(Math.max(settings.lotOpacity.startValue, Math.min(settings.lotOpacity.endValue, ease(lotOpacityT))));

        // APP OPACITY
        if (window.scrollY >= settings.appOpacity.scrollStart) {
            const appOpacityT = Math.max(0, Math.min(1, (window.scrollY - settings.appOpacity.scrollStart) / settings.appOpacity.scrollSpeed));
            setOpacity2(Math.max(0, Math.min(1, ease(appOpacityT))));
        } else {
            setOpacity2(0);
        };

        // ON THE GO OPACITY
        if (window.scrollY >= settings.onTheGoOpacity.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.onTheGoOpacity.scrollStart) / settings.onTheGoOpacity.scrollSpeed));
            setOnTheGoOpacity(1 - ease(t));
        } else {
            setOnTheGoOpacity(1);
        };

        // SPOT NAV OPACITY
        if (window.scrollY >= settings.spotNavOpacity.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.spotNavOpacity.scrollStart) / settings.spotNavOpacity.scrollSpeed));
            setSpotNavOpacity(ease(t));
        } else {
            setSpotNavOpacity(0);
        };

        // SECONDARY PHONE OPACITY
        if (window.scrollY >= settings.secondaryPhoneOpacity.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.secondaryPhoneOpacity.scrollStart) / settings.secondaryPhoneOpacity.scrollSpeed));
            setSecondaryPhoneOpacity(ease(t));
        } else {
            setSecondaryPhoneOpacity(0);
        };

        // SECONDARY PHONE Y
        if (window.scrollY >= settings.secondaryPhoneY.scrollStart) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.secondaryPhoneY.scrollStart) / settings.secondaryPhoneY.scrollSpeed));
            setSecondaryPhoneY(-150 + ease(t) * 100);
        } else {
            setSecondaryPhoneY(-150);
        };

        // REMOVE EVERYTHING AFTER 11000PX
        if (window.scrollY >= 8000) {
            const t = Math.max(0, Math.min(1, (window.scrollY - settings.secondaryPhoneOpacity.leaveStart) / (settings.secondaryPhoneY.scrollSpeed / 2)));
            setSecondaryPhoneOpacity(1 - ease(t));
            setSpotNavOpacity(1 - ease(t));
            setPrimaryPhoneOpacity(1 - ease(t));
        };
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (automated) {
            // Split the text into individual characters
            new SplitType('.practice', { types: 'chars' });
            let characters = document.querySelectorAll('.practice .char');

            // Apply a staggered animation to each character
            characters.forEach((char, index) => {
                setTimeout(() => {
                    char.style.opacity = '0';
                    char.style.transition = 'opacity 0.5s';
                    setTimeout(() => {
                        char.style.opacity = '1';
                        char.style.backgroundImage = 'linear-gradient(to right, #FFE374, #FEF08A)';
                        char.style.paddingBottom = '20px';
                        char.style.backgroundClip = 'text';
                        char.style.webkitBackgroundClip = 'text';
                        char.style.color = 'transparent';
                        char.style.transform = 'translateY(-40px)';
                        char.style.transition = 'opacity 0.5s, transform 0.5s';
                    }, index * 50);
                }, index * 50);
            });
        }
    }, [automated]);

    setTimeout(() => {
        setAutomated(true);
    }, 500);

    const IndustryCard = ({ title, description, image }) => {
        return (
            <div className='flex flex-col w-64 lg:w-96 items-center gap-5'>
                <img src={image} alt={title} className='object-cover w-96 h-56 rounded-lg hover:scale-105 hover:shadow-lg transition-all duration-200' />
                <h2 className='text-2xl font-bold w-full text-left'>{title}</h2>
                <p className='text-lg w-full text-left'>{description}</p>
            </div>
        );
    }

    const industryCards = [
        {
            title: 'Drivers',
            description: 'Spot Parking offers a seamless parking experience for retail customers. With real-time updates, customers can easily locate and navigate to the nearest open spot.',
            image: `${cdnUrl}drivers.png`
        },
        {
            title: 'Universities',
            description: 'Spot Parking provides a smart parking solution for commercial businesses. With real-time updates, businesses can monitor and manage parking capacity effortlessly.',
            image: `${cdnUrl}universities.jpeg`
        },
        {
            title: 'Local Businesses',
            description: 'Spot Parking transforms residential parking into a seamless experience. With real-time updates, residents can easily locate and navigate to the nearest open spot.',
            image: `${cdnUrl}localBusiness.png`
        },
        {
            title: 'Parking Lot Owners',
            description: 'Spot Parking offers a smart parking solution for healthcare facilities. With real-time updates, healthcare providers can easily monitor and manage parking capacity.',
            image: `${cdnUrl}parkingLotOverhead.jpg`
        },
        {
            title: 'Property Managers',
            description: 'Spot Parking provides a seamless parking experience for educational institutions. With real-time updates, students and faculty can easily locate and navigate to the nearest open spot.',
            image: `${cdnUrl}propertyManagement.jpeg`
        },
    ];

    return (
        <div className='flex flex-col items-center lg:mt-20 font-rubik w-full overflow-hidden'>
            {window.innerWidth > 760 && (
                <div className="z-10 absolute md:w-[800px] w-full text-white lg:text-9xl md:text-8xl sm:text-7xl text-4xl font-stretched font-bold top-32 transition-all pl-12 xl:left-36">
                    <h1>Capacity</h1>
                    <h1 id="text" className={`transition-all inline-block text-left text-transparent bg-clip-text font-stretched practice absolute top-[80px] sm:top-[200px]`}>Monitoring</h1>
                </div>
            )}

            {window.innerWidth > 760 && (
                <div className={`${wide ? 'h-[12800px]' : 'h-[10000px]'} w-screen overflow-hidden`}>
                    {window.scrollY <= (wide ? 13500 : 10000) && (
                        <div className={`flex flex-col ${window.innerWidth > 1080 ? 'mt-64' : wide ? 'mt-64' : 'mt-20'} w-screen items-center fixed left-1/2 transform -translate-x-1/2 ${wide ? 'scale-75' : 'scale-50'} xl:scale-90 2xl:scale-100`}>
                            <div
                                className={`absolute flex ${wide ? 'flex-row gap-5 -mt-64' : 'flex-col gap-2 -mt-80'} text-6xl xl:text-8xl md:text-6xl text-center text-white font-bold`}
                                style={{ opacity: spotNavOpacity }}
                            >
                                <h2 className="text-center text-white font-bold min-w-fit">Find Your</h2>
                                <h2 className={`text-spotYellow min-w-full ${wide ? 'text-left' : 'text-center'}`}>Spot</h2>
                            </div>
                            <h2
                                className={`absolute ${wide ? '-mt-4' : '-mt-24'} w-[500px] text-8xl text-center text-white font-bold z-30`}
                                style={{ opacity: onTheGoOpacity }}
                            >
                                On the <span className='text-spotYellow'>Go</span>
                            </h2>
                            <div className='flex justify-center scale-75 z-50 -mt-36'>
                                <div className={`${wide ? '' : 'mt-20'} z-50`}>
                                    <IPhoneFrame
                                        imageSrc={wide ? lot : lotVertical}
                                        imageSrc2={`${cdnUrl}deviceRenders/splashScreen.png`}
                                        rotate={rotate}
                                        scale={scale}
                                        x={0}
                                        coverOpacity1={coverOpacity1}
                                        coverOpacity2={coverOpacity2}
                                        totalOpacity={primaryPhoneOpacity}
                                    />
                                </div>
                                <div className='z-40 absolute'>
                                    <StaticIPhoneFrame
                                        imageSrc={`${cdnUrl}deviceRenders/listView.png`}
                                        rotate={0}
                                        scale={1}
                                        x={-230}
                                        opacity={secondaryPhoneOpacity}
                                        y={secondaryPhoneY}
                                    />
                                </div>
                                <div className='z-40 absolute'>
                                    <StaticIPhoneFrame
                                        imageSrc={`${cdnUrl}deviceRenders/mapView.png`}
                                        rotate={0}
                                        scale={1}
                                        x={230}
                                        opacity={secondaryPhoneOpacity}
                                        y={secondaryPhoneY}
                                    />
                                </div>
                            </div>
                            <div
                                className={`absolute flex flex-col gap-5 ${wide ? 'mt-[500px]' : 'mt-[600px]'} text-8xl w-screen text-center text-white font-bold`}
                                style={{ opacity: spotNavOpacity }}
                            >
                                <h2 className={`text-2xl text-center w-screen mt-10 ${wide ? 'xl:px-96 px-10 md:px-36' : 'px-0'} text-white font-normal`}>The Spot system makes parking effortless. With real-time updates, discover the nearest open spot and navigate directly to it. Experience convenience and efficiency like never before with our smart parking solution.</h2>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className={`font-bold text-center text-spotGray bg-white pt-12 w-screen no-scrollbar overflow-hidden z-[1000]`}>
                <div className='p-12 xl:p-24'>
                    <h2 className="lg:text-8xl text-4xl text-center text-spotGray font-bold">One Solution.</h2>
                    <h2 className="text-2xl lg:text-4xl text-center text-spotGray font-bold">Parking Made Simple.</h2>
                    <h2 className={`${wide ? 'text-2xl xl:px-96 mt-10' : 'text-md px-8 mt-4'} text-center text-spotGray font-normal`}>Spot Parking integrates cutting-edge technology with smart solutions—transforming parking into a seamless experience for everyone.</h2>
                </div>
                <div className="overflow-x-auto w-full p-4">
                    <div className="flex flex-row gap-10 w-max flex-nowrap px-4">
                        {industryCards.map((card, index) => (
                            <IndustryCard
                                key={index}
                                title={card.title}
                                description={card.description}
                                image={card.image}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className='text-xl sm:text-3xl md:text-5xl 2xl:text-8xl font-bold relative text-center text-spotGray bg-white w-screen justify-center items-center overflow-hidden'>
                <img src={`${cdnUrl}guy.png`} alt='guy' className='w-full object-contain' />
                <h2 className={`w-[80%] absolute top-[1/2] left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl shadow-inner-lg bg-spotGray text-white font-bold ${wide ? 'p-8' : 'py-4'} shadow-xl`}>
                    <p className='text-2xl sm:text-4xl md:text-5xl 2xl:text-7xl'>
                        Forgot where you parked?
                    </p>
                </h2>
                <div className='flex flex-col lg:flex-row gap-10 w-screen justify-between items-center px-4 md:px-24 pt-24 lg:h-[640px]'>
                    <h2 className="text-4xl md:text-5xl lg:text-6xl 2xl:text-8xl text-center text-spotGray font-semibold">Your car, just a click away.</h2>
                    <div className='relative h-64 sm:h-96 w-full lg:scale-125 2xl:h-[700px] select-none'>
                        <img src={`${cdnUrl}deviceRenders/findYourSpot.png`} alt='find your spot' className='absolute bottom-0 lg:-bottom-[25px] xl:-bottom-[30px] 2xl:bottom-[130px] object-contain z-30 h-64 sm:h-96 lg:left-[150px] xl:h-[400px]' />
                        <video className='absolute
                            h-[calc(130%-6px)] xl:h-[calc(132%+3px)] 2xl:h-[calc(73%-1px)]
                            -bottom-[84px] sm:-bottom-[127px] lg:-bottom-[152px] xl:-bottom-[159px] 2xl:bottom-[0px]
                            left-[16px] sm:left-[23px] lg:left-[173px] xl:left-[175px]
                            rounded-[19px] sm:rounded-[29px] lg:rounded-[30px]
                        z-[1000]' src={`${cdnUrl}deviceRenders/findYourSpotVideo.mov`} autoPlay loop muted playsInline webkit-playsinline />
                        <div className='absolute
                            w-2 sm:w-3
                            h-2 sm:h-3
                            left-[62px] sm:left-[92px] lg:left-[242px] xl:left-[246px]
                            top-[19px] sm:top-[29px] lg:top-[55px] xl:top-[45px] 2xl:top-[198px]
                            rounded-full
                            bg-black z-[11]' />
                    </div>
                </div>
            </div>

            {/* LEARN MORE SECTION */}
            <div className='text-4xl md:text-8xl font-</div>bold relative border-t-8 border-t-spotYellow flex flex-col gap-10 text-center text-spotGray bg-spotGray w-screen justify-center overflow-hidden p-8 md:p-12'>
                <div>
                    <h2 className="text-4xl md:text-8xl w-full text-left text-white font-semibold">Interested?</h2>
                    <h2 className="text-xl md:text-2xl w-full text-left text-white font-semibold">Discover how Spot Parking can transform your campus parking</h2>
                </div>
                <div className='flex flex-col md:flex-row md:justify-start gap-5 items-center'>
                    <div className='flex flex-row w-full md:w-fit'>
                        <input
                            type='text'
                            placeholder='Email Address'
                            className='w-full md:w-48 lg:w-96 h-12 md:h-16 rounded-l-xl text-xl md:text-2xl font-semibold text-spotGray p-4 outline-none'
                        />
                        <div className={`h-12 md:h-16 ${wide ? '' : 'w-[50%]'} px-2 md:px-6 font-bold text-spotGray text-lg sm:text-xl bg-spotYellow rounded-r-xl border-2 border-spotYellow transition-all hover:scale-105 hover:rounded-l-sm cursor-pointer duration-100 flex justify-center items-center`}>Learn More</div>
                    </div>
                    {window.innerWidth > 760 ?
                        <div className='w-[2px] h-12 md:h-16 border-l-1 bg-gray-400' /> :
                        <div className='w-full flex flex-row h-4 justify-center items-center gap-4'>
                            <div className='w-full h-[2px] bg-gray-400' />
                            <p className='text-white text-3xl'>or</p>
                            <div className='w-full h-[2px] bg-gray-400' />
                        </div>
                    }
                    <Link to='/contact'>
                        <div className='h-12 md:h-16 px-4 lg:px-6 w-full md:w-64 font-bold text-white text-xl lg:text-2xl bg-transparent rounded-xl border-2 border-spotYellow transition-all hover:bg-spotYellow hover:scale-105 hover:text-spotGray cursor-pointer duration-100 flex justify-center items-center'>Request a Demo</div>
                    </Link>
                </div>
            </div>
            <Footer bg={'#323232'} textColor={'white'} />
        </div >
    );
};