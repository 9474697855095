import React, { useEffect } from 'react';
import { Footer } from '../Footer';

const Accessibility = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="p-6 lg:p-20 pt-20 pb-0 font-sans gray-gradient text-white">
            <h1 className="font-bold text-2xl">Accessibility Statement</h1>
            <p className="mt-4">
                Spot Parking is committed to ensuring digital accessibility for all users, including individuals with disabilities.
                We are actively working to enhance the accessibility and usability of our website, striving to adhere to recognized standards.
            </p>

            <h2 className="font-bold text-lg mt-6">Conformance Status</h2>
            <p className="mt-4">
                This website aims to conform to the Web Content Accessibility Guidelines (WCAG) 2.1, Level AA. While we strive to meet these guidelines,
                there may be some areas that require further improvements. Accessibility is an ongoing process, and we are dedicated to regular updates
                and optimizations.
            </p>

            <h2 className="font-bold text-lg mt-6">Feedback and Assistance</h2>
            <p className="mt-4">
                We welcome your feedback on the accessibility of our website. If you encounter any barriers or need assistance, please contact us:
            </p>
            <ul className="list-disc ml-8 mt-2">
                <li>Email: <a href="mailto:info@spotparking.com" className="underline">info@spotparking.com</a></li>
            </ul>

            <h2 className="font-bold text-lg mt-6">Ongoing Efforts</h2>
            <p className="mt-4">
                Spot Parking conducts regular accessibility audits and provides training for our team to ensure compliance with current standards.
                Our goal is to deliver an inclusive experience for all users.
            </p>

            <h2 className="font-bold text-lg mt-6">Future Enhancements</h2>
            <p className="mt-4">
                We are continually working to improve our website’s accessibility. Your feedback plays a crucial role in helping us identify areas
                for improvement and prioritize updates.
            </p>

            <h2 className="font-bold text-lg mt-6">Contact Us</h2>
            <p className="mt-4">
                If you have questions or suggestions about accessibility on our website, please reach out to us at
                <a href="mailto:info@spotparking.com" className="underline ml-1">info@spotparking.com</a>.
            </p>
            <Footer bg="transparent" textColor="white" />
        </div>
    );
};

export default Accessibility;