import React, { useEffect } from "react";
import { postData } from "./fetcher";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

const Download = () => {
    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isMacOS = /Macintosh/.test(navigator.userAgent) && !window.MSStream;
        const downloadUrl = isIOS || isMacOS
            ? "https://apps.apple.com/us/app/spot-nav/id6736955321"
            : "https://play.google.com/store/apps/details?id=com.navnative";

        const sendCount = async () => {
            await postData("/general/incrementQRCounter", {});
            if (isIOS || isMacOS) {
                window.location.href = downloadUrl;
            } else {
                window.open(downloadUrl, '_blank');
            };
        };

        sendCount();
    }, []);

    return (
        <div className="p-20 font-sans h-screen flex flex-row gap-10 justify-center items-center">
            <a href="https://apps.apple.com/us/app/spot-nav/id6736955321" target='_blank' className="px-8 py-4 border-2 border-white rounded-full flex flex-row gap-4 hover:bg-white hover:text-spotGray justify-center items-center transition text-white text-4xl group">
                <FaApple size={36} className="text-6xl text-white group-hover:text-spotGray transition" />
                <h2>App Store</h2>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.navnative" target='_blank' className="px-8 py-4 border-2 border-white rounded-full flex flex-row gap-4 transition hover:bg-white group hover:text-spotGray justify-center items-center text-white text-4xl">
                <FaGooglePlay size={32} className="text-6xl text-white group-hover:text-spotGray transition" />
                <h2>Google Play</h2>
            </a>
        </div>
    );
};

export default Download; 